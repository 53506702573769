/* General styles */
.summary-container {
    padding: 20px;
    background-color: #f4f6f9;
  }
  
  .header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .search-bar {
    display: flex;
    justify-content: space-between;
 
  }
  
  .search-input {
    width: 20%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
 /* Table Styles */
.summary-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.summary-table thead {
    background-color: #003366;
}

.summary-table th {
    padding: 12px 15px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
}

.summary-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

/* Row Styles */
.summary-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.filter{
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  gap:23px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}
.filter select,
.filter input[type="date"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 190px;
}