/* Footer Base Styles */
.footer {
  background: linear-gradient(135deg, #003366, #006bb3); /* Deep blue to lighter blue gradient */
  color: white;
  text-align: center;
  padding: 0;
  height: 60px; /* Footer height */
  position: relative;
  font-family: 'Poppins', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.3); /* Deep shadow for depth */
  overflow: hidden; /* Ensures the wave stays inside the footer */
  z-index: 1;
}

/* Creating the Smoother Wave Effect */
.footer::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 200%; /* Make the wave larger than the footer */
  height: 100%;
  background: linear-gradient(135deg, #003365, #006bb3); /* Matching gradient */
  z-index: -1;
  clip-path: polygon(0% 70%, 10% 50%, 20% 70%, 30% 55%, 40% 80%, 50% 60%, 60% 85%, 70% 55%, 80% 75%, 90% 50%, 100% 70%, 100% 100%, 0% 100%); /* Smoother wave shape */
  animation: waveAnimation 8s cubic-bezier(0.68, -0.55, 0.27, 1.55) infinite; /* Smooth, natural wave animation */
}

/* Smoother Wave Animation (Moving left to right with ease) */
@keyframes waveAnimation {
  0% {
    transform: translateX(0); /* Start from the left */
  }
  100% {
    transform: translateX(-50%); /* Move the wave to the left */
  }
}

/* Footer Content */
.footer-content {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  position: relative;
  z-index: 2;
  font-size: 20px;
  font-weight: bold;
  color: white;
}
