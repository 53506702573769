/* Academy List Container */
.dues-list-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 10px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.top-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.search-bar {
  flex-grow: 1;
  margin-right: 20px;
  /* Adds some space between the search bar and total dues */
}

.search-input {

  padding: 8px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.total-dues {
  font-size: 18px;
  font-weight: bold;
  color: #333;
  padding: 10px;
  margin-right: 20px;
}
.items-and-dues {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
/* Dues Table */
.dues-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
  border-radius: 10px;
}

.dues-table th,
.dues-table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
}

.dues-table th {
  background-color: #003366;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}

.dues-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.dues-table tr:hover {
  background-color: #f1f1f1;
}

.dues-table td {
  vertical-align: middle;
}

/* Three Dots Menu */
.action-menu {
  position: relative;
}

.three-dots-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #555;
}

.three-dots-btn:hover {
  color: #000;
}

.dropdown-menu-dues {
  position: absolute;
  top: 35px;
  right: 0;
  background-color: white;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  padding: 8px;
  width: 130px;
  height: 30px;
}

.dropdown-item {
  background: none;
  border: none;
  font-size: 14px;
  width: 100%;
  text-align: left;
  cursor: pointer;
  padding: 5px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.filter-container-dues {
  margin-top: 30px;
  display: flex;
  flex-wrap: wrap;
  gap: 17px;
  padding: 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
  margin-bottom: 20px;
}

.filter-container-dues select,
.filter-container-dues input[type="date"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
}
.filter-container-summer {
  display: flex;
  flex-wrap: wrap;
  gap: 85px;
  padding: 2px 20px;
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 8px;
}

.filter-container-summer select,
.filter-container-summer input[type="date"] {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  min-width: 200px;
}