/* General Container for the Create Player Form */
.create-player-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  /* Title of the Form */
  .create-player-title {
    font-size: 30px;
    margin-bottom: 20px;
    color: #001861;
    text-align: center;
  }
  
  /* Form Layout */
  .create-player-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* Label for Inputs */
  .input-label {
    font-size: 15px;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
  }
  
  /* General Input Field */
  .input-field,
  .select-field {
    padding: 10px;
    font-size: 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    outline: none;
    transition: border 0.3s ease;
  }
  
  /* Focus Effect on Input Fields */
  .input-field:focus,
  .select-field:focus {
    border-color: #007bff;
  }
  
  /* Table for Parent Information */
  .parent-info-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
  }
  
  .parent-info-table th, .parent-info-table td {
    padding: 12px;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .parent-info-table th {
    background-color: #f2f2f2;
    font-weight: bold;
  }
  
  /* Submit Button Styling */
  .submit-button {
    padding: 12px 20px;
    font-size: 20px;
    background-color: #006bb3;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-button:hover {
    background-color: #0056b3;
  }
  
  /* Checkbox Styling */
  .checkbox-field {
    width: 20px;
    height: 20px;
  }
  
  .checkbox-label {
    font-size: 15px;
    color: #555;
    cursor: pointer;
  }

.input-container-hide-profile-stats {
  display: inline-flex;   
  align-items: center;    
  margin-top: 10px;
  gap: 10px;              
}


.parent-info-title{
  margin: 0px;
}