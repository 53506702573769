.firstb-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.firstb-modal {
  background: white;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  position: relative;
}

.firstb-modal h3 {
  margin-bottom: 15px;
  text-align: center;
}

.firstb-modal-row {
  margin-bottom: 12px;
  display: flex;
  flex-direction: column;
}

.firstb-modal-row label {
  font-weight: bold;
  margin-bottom: 5px;
}

.firstb-modal-row textarea {
  
  height: 80px;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 8px;
  resize: none;
}

.firstb-status-radio-group {
  display: flex;
  flex-direction: column; /* Stack items in a column */
  gap: 8px;
}

.firstb-status-radio-group div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.firstb-modal-row button {
  padding: 8px 15px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: bold;
}

.firstb-modal-row button:first-child {
  background-color: #4CAF50;
  color: white;
  margin-right: 10px;
}

.firstb-modal-row button:last-child {
  background-color: #f44336;
  color: white;
}

.firstb-modal-row button:hover {
  opacity: 0.8;
}
