/* Table Styling */
.player-payment-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
  font-size: 1rem;
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.player-payment-table th, .player-payment-table td {
  padding: 16px 24px;
  vertical-align: middle;
  font-size: 1rem;
  text-align: left;
  border-bottom: 2px solid #eee;
  border-right: 2px solid #ddd; /* Add right border to all columns */
}

/* Remove right border from last column */
.player-payment-table th:last-child, .player-payment-table td:last-child {
  border-right: none;
}

/* Add left border to the first column */
.player-payment-table th:first-child, .player-payment-table td:first-child {
  border-left: 2px solid #ddd;
}

/* Header Styling */
.player-payment-table th {
  background-color: #001861;
  color: white;
  font-weight: 600;
  text-align: left;
  position: sticky;
  top: 0;
  z-index: 1; /* Ensures header stays on top when scrolling */
}

/* Add column separation line between th */
.player-payment-table th:not(:last-child) {
  border-right: 2px solid #ddd;
}

.player-payment-table tbody tr:nth-child(even) {
  background-color: #f9f9f9;
}

.player-payment-table tbody tr:hover {
  background-color: #f1f1f1;
  cursor: pointer;
}

.player-payment-table td {
  color: #666;
  vertical-align: middle;
}

/* Input Fields in Table */
.player-payment-input {
  padding: 12px 18px;
  width: 100%;
  border: 2px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  color: #333;
  transition: all 0.3s ease-in-out;
  background-color: #f9f9f9;
}

.player-payment-input:focus {
  outline: none;
  border-color: #009688;
  box-shadow: 0 0 8px rgba(0, 150, 136, 0.2);
  background-color: #fff;
}

/* Checkbox Styling */
.player-payment-checkbox {
  width: 20px;
  height: 20px;
  cursor: pointer;
  margin: 0;
}

/* Column Specific Styling */
.player-payment-table-cell:last-child {
  font-weight: 600;
  color: #333;
}

/* Save Button Styling */
.player-payment-save-button {
  padding: 12px 24px;
  font-size: 1.1rem;
  background-color: #009688;
  color: white;
  border: none;
  border-radius: 6px;
  cursor: pointer;
  transition: all 0.3s ease-in-out;
}

.player-payment-save-button:hover {
  background-color: #00796b;
}

.player-payment-save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Responsive Design */
@media (max-width: 768px) {
  .player-payment-container {
    padding: 15px;
  }

  .player-payment-search-input {
    width: 80%;
  }

  .player-payment-table {
    font-size: 0.95rem;
  }

  .player-payment-table th, .player-payment-table td {
    padding: 12px;
  }

  .player-payment-save-button {
    font-size: 1rem;
  }
}

@media (max-width: 480px) {
  .player-payment-title {
    font-size: 2rem;
  }

  .player-payment-search-input {
    width: 100%;
  }

  .player-payment-save-button {
    font-size: 1rem;
  }

  .player-payment-table {
    font-size: 0.9rem;
  }

  .player-payment-table th, .player-payment-table td {
    padding: 10px;
  }
}
/* Make the date input smaller */
.player-payment-input {
  padding: 8px 12px; /* Reduced padding to make it smaller */
  font-size: 0.9rem; /* Smaller text inside the input */
  width: auto; /* Adjust width to fit the content */
  max-width: 110px; /* Optional: limit the maximum width of the input */
}

/* Additional adjustments for better alignment */
.player-payment-input:focus {
  outline: none;
  border-color: #009688;
  box-shadow: 0 0 8px rgba(0, 150, 136, 0.2);
  background-color: #fff;
}
