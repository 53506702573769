.add-unavailable-date__container {
  padding: 20px;
  font-family: 'Poppins', sans-serif;
  max-width: 1100px;
  margin: 0 auto;
  background-color: #f4f6f9;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.add-unavailable-date__title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 20px;
  text-align: center;
  color: #333;
}

.add-unavailable-date__form {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.add-unavailable-date__form-group {
  display: flex;
  flex-direction: column;
}

.add-unavailable-date__label {
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  color: #555;
}

.add-unavailable-date__input-court-name {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
}

.add-unavailable-date__input-court-name:focus {
  border-color: #007BFF;
  outline: none;
}

.add-unavailable-date__input-start-date {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
}

.add-unavailable-date__input-start-date:focus {
  border-color: #007BFF;
  outline: none;
}


.add-unavailable-date__input-end-date {
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 5px;
  transition: border-color 0.2s;
}

.add-unavailable-date__input-end-date:focus {
  border-color: #007BFF;
  outline: none;
}

.add-unavailable-date__submit-button {
  padding: 10px 15px;
  font-size: 16px;
  color: white;
  background-color: #007BFF;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.2s;
}

.add-unavailable-date__submit-button:hover {
  background-color: #0056b3;
}