/* Navbar Styling */
.navbar {
  display: flex;
  padding: 0px 20px;
  background-color:#003366;
  color: white;
  box-shadow: 0 4px 19px rgba(0, 0, 0, 0.1);
  z-index: 10;
}

.logo-container {
  display: flex;
  align-items: center;
}

.logo {
  height: 50px;
  width: 50px;
  margin-right: 15px;
  object-fit: contain;
}

.logo-container h1 {
  font-size: 2rem;
  margin: 0;
  font-weight: bold;
  letter-spacing: 4px;
  font-family: 'Poppins', sans-serif;
}

/* Login Container Layout */
.login-container {
  display: flex;
  height: 742px;
  justify-content: space-between;
  overflow: hidden;
  
}

.left-section {
  width: 40%;
  height: 100%;
  background: url('https://www.bois-colombes.fr/wp-content/uploads/2021/09/sport-2.svg') no-repeat center center;
  /* background-size: cover; */
  background-position: center;
  position: relative;
  background-color:#ccc;
}

.right-section {
  width: 60%;
  background-color: #ffffff38;
  padding: 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-shadow: -4px 0px 10px rgba(0, 0, 0, 0.1);
  position: relative;
  margin-bottom: 200px;
  height: 100%;
}

/* Form Styling */
.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
  width: 100%;
  max-width: 350px;
}

.login-form input {
  padding: 15px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 8px;
  outline: none;
  transition: border-color 0.3s ease;
}

.login-form input:focus {
  border-color: #007bff;
}

.button {
  padding: 15px;
  font-size: 1rem;
  background-color: #003366;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.button:hover {
  background-color: #0056b3;
}

.error-message {
  color: red;
  margin-top: 10px;
  font-size: 10px;
  text-align: center;
}

.button:disabled {
  background-color: #ccc;
  cursor: not-allowed;
}

.heading_login{
  color: white;
  text-align: center;
}
