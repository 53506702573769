
  /* Main container */
  .checkin-container {
    max-width: 1100px;
    margin: 20px auto;
    padding: 40px;
    background-color: #f4f6f9;
    border-radius: 15px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    transition: box-shadow 0.3s ease;
  }
  
  /* Hover effect on the container */
  .checkin-container:hover {
    box-shadow: 0 12px 24px rgba(0, 0, 0, 0.1);
  }
  
  /* Title */
  .checkin-title {
    font-size: 32px;
    font-weight: 600;
    color: #2c3e50;
    margin-bottom: 20px;
    text-align: center;
    text-transform: uppercase;
    letter-spacing: 2px;
    padding-bottom: 10px;
    border-bottom: 2px solid #3498db;
  }
  
  /* Form Layout */
  .checkin-form {
    display: flex;
    flex-direction: column;
    gap: 25px;
  }
  
  /* Form Groups */
  .checkin-form-group {
    display: flex;
    align-items: center;
    gap: 20px;
  }
  
  /* Label Styling */
  .checkin-form-label {
    font-size: 18px;
    font-weight: 500;
    color: #34495e;
    width: 25%; /* Adjusted width for better spacing */

  }
  
  /* Input & Textarea Styling */
  .checkin-form-input {
    padding: 14px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #333;
    transition: all 0.3s ease;
    cursor: not-allowed;
    width: 70%; /* Adjusted width for inputs */
  }
  
  .checkin-form-input:disabled {
    background-color: #eaeaea;
    color: #b0b0b0;
  }
  
  .checkin-form-input:enabled:hover {
    border-color: #3498db;
    box-shadow: 0 0 8px rgba(52, 152, 219, 0.2);
  }
  
  /* Textarea (message) */
  textarea.checkin-form-input {
    resize: vertical;
    min-height: 120px;
  }
  
  /* Payment Dues Box */
  .checkin-payment-dues {
    background-color: #fce4e4;
    border-radius: 8px;
    padding: 10px;
    border: 1px solid #f5c6cb;
    font-size: 18px;
    font-weight: 500;
    color: #e74c3c;
    transition: all 0.3s ease;
  }
  
  .checkin-payment-dues-text {
    margin-bottom: 8px;
  }
  
  /* Payment Date Styling */
  .checkin-payment-date {
    font-size: 14px;
    font-weight: 400;
    color: #7f8c8d;
    margin-top: 5px;
  }
  
  /* Hover effect for Payment Dues */
  .checkin-payment-dues:hover {
    background-color: #f8d7da;
    border-color: #f1b0b7;
    box-shadow: 0 4px 8px rgba(231, 76, 60, 0.1);
  }
  
  /* Stylish Button */
  .checkin-mark-attendance-btn {
    padding: 12px 25px;
    font-size: 16px;
    background-color: #3498db;
    color: #fff;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    margin-top: 20px;
  }
  
  .checkin-mark-attendance-btn:hover {
    background-color: #2980b9;
    box-shadow: 0 4px 12px rgba(52, 152, 219, 0.2);
  }
  
  .checkin-mark-attendance-btn:disabled {
    background-color: #95a5a6;
    cursor: not-allowed;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .checkin-container {
      padding: 25px;
      margin: 15px;
    }
  
    .checkin-title {
      font-size: 26px;
    }
  
    .checkin-form-input {
      font-size: 14px;
      width: 100%; /* Full width for inputs on mobile */
    }
  
    .checkin-form-group {
      gap: 12px;
    }
  
    .checkin-form-group .checkin-form-label {
      flex-basis: 40%; /* Adjust the label width for smaller screens */
    }
  
    .checkin-form-group .checkin-form-input {
      flex-grow: 1; /* Ensure input takes full width */
    }
  }
  