/* Dashboard.css */

/* Container for the entire dashboard */
.dashboard-container {
    background: #f4f6f9;
    max-width: 1200px;
    margin: 20px auto;
    padding: 20px;
    text-align: center;
    font-family: 'Poppins', sans-serif;
    color: #333;
  }
  
  /* Title of the dashboard */
  .dashboard-title {
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 40px;
    color: #2c3e50;
  }
  
  /* Container for the row of charts */
  .charts-row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 20px; /* Space between charts */
    flex-wrap: nowrap; /* Prevent wrapping to the next line */
    overflow-x: auto; /* Allows horizontal scrolling if needed */
  }
  
  /* Common styles for each chart container */
  .chart-container {
    width: 23%; /* Each chart takes up 23% of the container width */
    background-color: #fff;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    text-align: center;
  }
  
  .chart-title {
    font-size: 18px;
    font-weight: 600;
    color: #2c3e50;
    margin:0px;
  }
  
  /* Styling the Pie charts to give them some space and make them responsive */
  .pie-chart {
    max-width: 100%;
    max-height: 100%;
    margin: 0 auto;
  }
  
  /* Responsive design: Adjust the layout for smaller screens */
  @media (max-width: 1024px) {
    .chart-container {
      width: 48%; /* 2 charts per row on medium screens */
    }
  }
  
  @media (max-width: 768px) {
    .charts-row {
      flex-direction: column; /* Stack charts vertically on smaller screens */
      align-items: center;
    }
  
    .chart-container {
      width: 90%; /* Each chart takes up 90% of the container on mobile screens */
      margin-bottom: 20px;
    }
  
    .dashboard-title {
      font-size: 24px;
    }
  
    .chart-title {
      font-size: 16px;
    }
  }
  /* Container for the pie chart */
.status-chart-container {
  width: 100%;
  max-width: 500px; /* Optional: limit the maximum width */
  margin: 0 auto;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 300px; /* Keep the height fixed as per your requirement */
  box-sizing: border-box;
}

/* Title styling */
.status-chart-title {
  font-size: 1.25rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: 1px;
}

/* Pie chart styles */
.status-pie-chart {
  width: 100%;
  height: 80%; /* Maintain the height while keeping the aspect ratio */
  max-height: 250px; /* Optional: max height if you need the chart to fit */
  display: block;
}
/* Container for all pie charts */
.pie-charts-container {
  display: flex;
  flex-direction: row; /* Arrange charts in a row */
  overflow-x: auto; /* Enable horizontal scrolling */
  gap: 20px; 
  margin: 20px 0; /* Margin for spacing */
  max-width: 1200px;
}

/* Individual pie chart styling */
.pie-chart {
  flex: 0 0 auto; /* Prevent charts from shrinking or growing */
  width: 300px; /* Fixed width for each chart */
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Combined pie chart styling */
.pie-chart-container {
  flex: 0 0 auto; /* Prevent the combined chart from shrinking or growing */
  width: 300px; /* Fixed width for the combined chart */
  text-align: center;
  border: 1px solid #ddd;
  padding: 10px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Heading for pie charts */
.pie-chart h3,
.pie-chart-container h3 {
  margin-bottom: 10px;
  font-size: 18px;
  color: #333;
}


.showing-entries {
  margin-right: auto;
  color: #666;
  font-size: 0.9rem;
}
