.fees-setting-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1100px;
  width: 100%;
  box-sizing: border-box;
  padding: 15px;
}

.header {
  font-size: 30px;
  margin-bottom: 20px;
  color: #001861;
  text-align: center;
}


.fees-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  font-size: 14px;
  color: #555;
  margin-bottom: 8px;
}

.form-group input {
  width: 100%;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  height: 40px;
}

.form-group input:focus {
  outline: none;
  border-color: #007bff;
}


.checkbox-group {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;
}

.checkbox-group label {
  font-size: 14px;
  color: #555;
}

.checkbox-group input {
  margin-right: 8px;
}


.submit-button {
  padding: 12px;
  background-color: #28a745;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-button:hover {
  background-color: #218838;
}


.upload-button {
  display: inline-block;
  padding: 10px 15px;
  background-color: #006bb3;
  color: white !important;
  border-radius: 5px;
  cursor: pointer;
  text-align: center;
  font-size: 14px;
  font-weight: bold;
  transition: background-color 0.3s ease;
  width: 15%;
}