.court-price-container {
  width: 95%;
  margin: 10px auto;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.title {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.select-container {
  display: flex;
  justify-content: space-around;
  margin-bottom: 30px;
}

.select-label {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  font-size: 16px;
  color: #333;
  margin-right: 20px;
}

.select-input {
  padding: 10px;
  font-size: 14px;
  margin-top: 5px;
  border-radius: 5px;
  border: 1px solid #ccc;
  width: 200px;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.select-input:hover {
  border-color: #66a6ff;
}

.pricing-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 20px;
}

.pricing-table th {
  padding: 15px;
  background-color: #001861;
  color: white;
  text-align: center;
}

.pricing-table td {
  padding: 10px;
  text-align: center;
  border: 1px solid #ddd;
}

.price-input {
  padding: 8px;
  font-size: 14px;
  width: 60px;
  border-radius: 5px;
  border: 1px solid #ccc;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.price-input:hover {
  border-color: #66a6ff;
}

.price-input:focus {
  border-color: #66a6ff;
  outline: none;
}