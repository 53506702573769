/* General styles */
.sport-payment-container {
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
   
}

.header {
    font-size: 24px;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    justify-content: space-between;

}

.search-input {
    width: 20%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Table Styles */
.sport-payment-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.sport-payment-table thead {
    background-color: #003366;
}

.sport-payment-table th {
    padding: 12px 15px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
}

.sport-payment-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

/* Row Styles */
.sport-payment-table tr:nth-child(even) {
    background-color: #f9f9f9;
}
.filter-container-sportpayment {
    margin-top: 30px;
    display: flex;
    flex-wrap: wrap;
    width:fit-content;
    gap:30px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
    margin-top: 15px;

  }
  .filter-container-sportpayment select,
  .filter-container-sportpayment input[type="date"] {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 200px;
  }