/* Form container */
.form-container {
  background-color: white;
  padding: 30px;
  border-radius: 8px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  width: 100%; /* Ensures the form takes up full width of the container */
  max-width: none; /* Maximum width for large screens */
  height: 100%; /* Allow height to adjust based on content */
  box-sizing: border-box;
}

h2 {
  text-align: center;
  color: #333;
  margin-bottom: 20px;
}

/* Form input and select fields */
.form-group {
  margin-bottom: 20px;
}

label {
  display: block;
  font-size: 16px;
  color: #555;
  margin-bottom: 8px;
}

.input-field {
  width: 100%; /* Ensures input fields take full width */
  padding: 10px;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
  background-color: #f9f9f9;
  box-sizing: border-box;
}

/* Dropdown container */
.dropdown-container {
  position: relative;
}

.dropdown-toggle {
  width: 100%; /* Ensures dropdown button takes full width */
  padding: 10px;
  text-align: left;
  cursor: pointer;
  background-color: white;
  color: #333;
  border: 1px solid #ddd;
  border-radius: 5px;
  font-size: 14px;
}
.dropdown-menu {
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Dropdown menu width */
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 200px; /* Limit dropdown height */
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.dropdown-item {
  padding: 10px;
  cursor: pointer;
  font-size: 14px;
}

.dropdown-item:hover {
  background-color: #f1f1f1;
}

.selected {
  background-color: #e0f7fa;
}

.save-button {
  padding: 12px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  width: 100%; 
  margin-top: 10px;
}

.save-button:hover {
  background-color: #45a049;
}
.remove-button {
  background: none;
  border: none;
  color: #ff4d4f;
  font-size: 20px;
  cursor: pointer;
  margin-left: 99%;
}
.remove-button:hover {
  color: #ff1a1a;
  background-color:white;
}
.add-more-button{
  padding: 14px;
  font-size: 13px;
  background-color: #001861;
  color: #fff;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.dropdown-container .dropdown-menu {
  display: block; /* Show the dropdown */
  position: absolute;
  top: 100%;
  left: 0;
  width: 100%; /* Dropdown menu width */
  background-color: white;
  border: 1px solid #ddd;
  border-top: none;
  max-height: 200px; /* Limit dropdown height */
  overflow-y: auto;
  z-index: 10;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}
