.new-court-container {
  width: 90%;
  margin: 20px auto;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.new-court-title {
  text-align: center;
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
}

.new-court-form-group {
  margin-bottom: 20px;
}

.new-court-label {
  display: block;
  margin-bottom: 8px;
  font-weight: bold;
  color: #555;
}

.new-court-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.new-court-select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.new-court-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 15px;
}

.new-court-table th,
.new-court-table td {
  border: 1px solid #ddd;
  padding: 10px;
  text-align: center;
}

.new-court-time-input {
  width: 95%;
  padding: 8px;
  font-size: 14px;
}

.new-court-button-group {
  margin-top: 20px;
  text-align: right;
}

.new-court-add-button {
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.new-court-add-button:hover {
  background-color: #45a049;
}

.new-court-remove-button {
  background-color: #ff4d4f;
  color: white;
  padding: 8px 16px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
}

.new-court-remove-button:hover {
  background-color: #d43f3a;
}

.new-court-submit-container {
  text-align: center;
  margin-top: 30px;
}

.new-court-submit-button {
  background-color: #007BFF;
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 16px;
  width: 100%;
}

.new-court-submit-button:hover {
  background-color: #0056b3;
}

.radio-label {
  color: black;
}