/* General Styles */
.progressattribute-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
}

/* Header */
.progressattribute-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #001861;
  text-align: center;
}

/* Table Styles */
.progressattribute-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.progressattribute-header-row {
  background-color: #001861;
}

.progressattribute-column {
  padding: 12px 15px;
  font-weight: bold;
  color: white;
  text-align: left;
  text-transform: uppercase;
}

.progressattribute-cell {
  padding: 12px 15px;
  font-size: 14px;
  vertical-align: middle;
  border: 1px solid #ddd;
}

/* Row Hover and Alternate Styles */
.progressattribute-row:nth-child(even) {
  background-color: #f9f9f9;
}

.progressattribute-row:hover {
  background-color: #f1f1f1;
}

/* Action Buttons */
.progressattribute-dots-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}


/* Dropdown Toggle */
.progressattribute-dots-btn.active {
  display: block;
}

/* Modal Styles */
.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.progressattribute-page-number {
  background-color: #001861;
  color: white;
  border: none;
  margin: 0 5px;
  cursor: pointer;
  width: 10px;
  height: 30px;
  margin-top:8px;
  text-align: center;
}

.progressattribute-page-number.active {
  background-color: #001861;
}

.progressattribute-pagination-dots {
  margin: 0 5px;
  font-size: 16px;
  color: #555;
}


.prev-button:disabled,
.next-button:disabled {
  cursor: not-allowed;
  background-color: #ddd;
}
/* Dropdown Menu Styles */
.progressattribute-dots-btn {
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
  padding: 5px;
}

.progressattribute-dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ccc;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  z-index: 1000;
  width: 200px;
  top: 40%;
  margin-left: 10px;
  padding: 5px ;
  border-radius: 5px;
}

.progressattribute-dropdown-menu.visible {
  display: block;
}

.progressattribute-dropdown-item {
  background-color: white;
  color: #333;
  border: none;
  width: 100%;
  text-align: left;
  cursor: pointer;
}

.progressattribute-dropdown-item:hover {
  background-color: #f1f1f1;
}

.progressattribute-dropdown-item:focus {
  outline: none;
}
.qa-action {
  display: flex;
  gap: 10px; 
  align-items: center;
  background-color: none;
  border: none;
}
