.sport-list-container {
  padding: 10px;
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
  }
  .create-button {
    padding: 12px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .create-button:hover {
    background-color: #45a049;
  }
  
  .sport-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .sport-table th,
  .sport-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
  }
  
  .sport-table th {
    background-color: #00356B;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .sport-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .sport-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .sport-table td {
    vertical-align: middle;
  }

  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .edit-btn {
    background-color: green;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .edit-btn:hover {
    background-color: green;
  }
  
  .delete-btn {
    background-color: #ff4d4f;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }

  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h2 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .modal-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .confirm-btn {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .confirm-btn:hover {
    background-color: #45a049;
  }
  
  .cancel-btn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .cancel-btn:hover {
    background-color: #e53935;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    color: #555;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: #000;
  }
  .checkbox {
    display: flex;
    align-items: center; 
    gap: 7px; 
    margin-left: 4px;
  }
  .checkbox input[type="checkbox"] {
    margin: 0; 
    padding: 0;
    vertical-align: middle;
    width: 10px;
  }  

.sport-actions-column .action-button {
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
  padding: 5px;
  color: #333;
  position: relative;
}


.action-dropdown-menu {
  position: absolute;
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: 150px;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}


.action-dropdown-menu button {
  background: none;
  border: none;
  text-align: left;
  width: 100%;
  padding: 10px 15px;
  font-size: 0.9rem;
  cursor: pointer;
  color: #333;
  white-space: nowrap;
  transition: background-color 0.3s, color 0.3s;
}

@media (max-width: 768px) {
  /* Main container */
  .sport-list-container {
    padding: 0;  /* No padding */
    width: 100%;
    max-width: 100%;
    margin: 0;  /* No margin */
  }
   /* Table */
   .sport-table th,
   .sport-table td {
     padding: 2px 4px;  /* Minimal padding */
     font-size: 6px;  /* Very small font size */
   }
 
   .sport-table th {
     font-size: 7px;  /* Slightly larger for header */
   }
 
   .sport-table tr:nth-child(even) {
     background-color: #f9f9f9;
   }
 
   .sport-table tr:hover {
     background-color: #f1f1f1;
   }
 .prev-button{
  width: auto;
 }
 .next-button{
  width: auto;
 }
 .pagination {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin: 20px 0;

}

}