.academy-list-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
      margin: 0px auto;
}
.search-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.search-input {
  width: 20%;
  padding: 10px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 14px;
  box-sizing: border-box;
}

.create-button {
  padding: 12px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 8px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;

}

.create-button:hover {
  background-color: #45a049;
}

.academy-table {
  width: 100%;
  border-collapse: collapse;
  margin-top: 10px;
}

.academy-table th,
.academy-table td {
  border: 1px solid #ddd;
  padding: 12px 15px;
  text-align: left;
  font-size: 14px;
}

.academy-table th {
  background-color: #00356B;
  color: white;
  font-weight: 600;
  text-transform: uppercase;
}

.academy-table tr:nth-child(even) {
  background-color: #f9f9f9;
}

.academy-table tr:hover {
  background-color: #f1f1f1;
}

.academy-table td {
  vertical-align: middle;
  border-bottom: none;
}

.notification-tag {
  display: inline-block;
  padding: 5px 10px;
  border-radius: 20px;
  font-size: 12px;
  text-transform: capitalize;
}

.notification-active {
  background-color: #4caf50;
  color: white;
}

.notification-inactive {
  background-color: #ff4d4f;
  color: white;
}

.action-buttons {
  display: flex;
  gap: 10px;
}

.edit-btn {
  background-color: green;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.edit-btn:hover {
  background-color: green;
}

.delete-btn {
  background-color: #ff4d4f;
  border: none;
  color: white;
  padding: 8px 12px;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  font-size: 14px;
}

.delete-btn:hover {
  background-color: #d32f2f;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px 30px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  text-align: center;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
}

.modal-content h2 {
  font-size: 20px;
  margin-bottom: 15px;
  color: #333;
}

.modal-content p {
  font-size: 16px;
  color: #555;
  margin-bottom: 20px;
}

.modal-actions {
  display: flex;
  gap: 10px;
  justify-content: center;
}

.confirm-btn-academy {
  padding: 10px 20px;
  background-color: #4caf50;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}

.cancel-btn-academy {
  padding: 10px 20px;
  background-color: #f44336;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  cursor: pointer;
}
.close-btn {
  position: absolute;
  top: 10px;
  right: 15px;
  background: none;
  border: none;
  font-size: 20px;
  color: #555;
  cursor: pointer;
}

.close-btn:hover {
  color: #000;
}

.checkbox {
  display: flex;
  align-items: center;
  gap: 7px;
  margin-left: 4px;
}

.checkbox input[type="checkbox"] {
  padding: 0;
  vertical-align: middle;
  width: 10px;
}

.pagination {
  display: flex;
  justify-content: end;
  gap: 8px;
  margin: 20px 0;

}

.prev-button,
.next-button {
  background-color: #00356B;
  color: white;
  border: none;
  padding: 8px 16px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  margin-bottom: 15px;
}

.prev-button:disabled,
.next-button:disabled {
  background-color: #c0c0c0;
  cursor: not-allowed;
}

.page-number {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  padding: 8px 12px;
  font-size: 11px;
  cursor: pointer;
  border-radius: 4px;
  transition: background-color 0.3s;
  height: 30px;   
}

.next-button {
  margin-right: 3px;
}

.page-number.active {
  background-color: #007bff;
  color: white;
}

.page-number:focus {
  outline: none;
}

.ellipsis-button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  color: #555;
  padding: 8px;
  transition: color 0.3s;
}

.ellipsis-button:hover {
  color: #007bff;
}


.custom-dropdown {
  position: absolute;
  top: 70%; 
  background-color: #ffffff;
  border: 1px solid #ddd;
  border-radius: 4px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
  padding: 8px 0;
  z-index: 1000;
  width: 150px;
  display: flex;
  flex-direction: column;
  right: 0;
}

.custom-dropdown button {
  background: none;
  border: none;
  text-align: left;
  padding: 0px 16px;
  font-size: 14px;
  color: #333;
  cursor: pointer;
  transition: background-color 0.2s, color 0.2s;
}

.custom-dropdown button:hover {
  background-color: #f8f9fa;
  color: #007bff;
}

.action-buttons {
  position: relative;
}
.academy-list-container a {
  text-decoration: none; 
  color: #00356B;
  font-size: 15px;
}.sort-arrow {
  color: white;
  display: inline;
}
/* Ultra-Compact for phones with screen width <= 320px */
@media (max-width: 768px) {
  /* Main container */
  .academy-list-container {
    padding: 0;  /* No padding */
    width: 100%;
    max-width: 100%;
    margin: 0;  /* No margin */
  }



  .search-input {
    width: 100%;  /* Full width */
    padding: 2px 4px;  /* Very minimal padding */
    font-size: 6px;  /* Extremely small font size */
    margin-bottom: 2px;  /* Tiny margin */
  }

  .create-button {
    width: 100%;
    padding: 0px;
    font-size: 6px;  /* Extremely small font size */
  }

  /* Table */
  .academy-table th,
  .academy-table td {
    padding: 2px 4px;  /* Minimal padding */
    font-size: 6px;  /* Very small font size */
  }

  .academy-table th {
    font-size: 7px;  /* Slightly larger for header */
  }

  .academy-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .academy-table tr:hover {
    background-color: #f1f1f1;
  }

  /* Notification tags */
  .notification-tag {
    font-size: 5px;  /* Smallest font size */
    padding: 2px 4px;  /* Small padding */
  }

  /* Action buttons (edit/delete) */
  .action-buttons {
    flex-direction: column;
    gap: 1px;  /* Extremely small gap */
    align-items: stretch;
  }

  .edit-btn, .delete-btn {
    width: 100%;
    padding: 3px;
    font-size: 5px;  /* Very small font size for buttons */
  }

  /* Modal */
  .modal-content {
    padding: 4px;
    width: 90%;
    max-width: 250px;  /* Smaller max width */
  }

  .modal-content h2 {
    font-size: 7px;  /* Ultra-small modal header */
  }

  .modal-content p {
    font-size: 6px;  /* Smaller modal body text */
  }

  /* Modal action buttons */
  .confirm-btn-academy,
  .cancel-btn-academy {
    width: 100%;
    padding: 4px;
    font-size: 5px;  /* Smallest font size for buttons */
  }

  /* Close button in modal */
  .close-btn {
    font-size: 8px;  /* Small close button */
  }

  /* Checkbox */
  .checkbox {
    font-size: 5px;  /* Ultra-small font size */
  }

  /* Pagination */
  .pagination {
    flex-direction: column;
    gap: 1px;  /* Minimal gap */
    align-items: center;
  }

  .prev-button,
  .next-button {
    width: 100%;
    padding: 4px;
    font-size: 5px;  /* Very small button text */
  }

  .page-number {
    font-size: 5px;  /* Ultra-small page number */
    padding: 2px 4px;  /* Small padding */
  }

  /* Ellipsis button for dropdown */
  .ellipsis-button {
    font-size: 6px;  /* Very small ellipsis icon */
  }

  /* Custom dropdown */
  .custom-dropdown {
    top: 25%;  /* Adjusted for smaller screens */
    right: 0;
    width: 70px;  /* Extremely narrow dropdown */
  }

  .custom-dropdown button {
    font-size: 5px;  /* Smallest font size */
    padding: 0px 4px;  /* Tiny padding */
  }

  /* Links */
  .academy-list-container a {
    font-size: 6px;  /* Small font size for links */
  }

  /* Action buttons positioning */
  .action-buttons {
    position: relative;
  }
}* {
  font-family: 'Poppins', sans-serif;
}
