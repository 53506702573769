.reset-password-container {
  background-color: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  padding: 20px;
  box-sizing: border-box;
}

.reset-password-container h2 {
  text-align: center;
  font-size: 24px;
  font-weight: bold;
  color: #00356B;
  margin-bottom: 20px;
}


.input-group {
  margin-bottom: 20px;
}

.input-group label {
  display: block;
  font-size: 14px;
  font-weight: 500;
  color: #333;
  margin-bottom: 8px;
}

.input-group input {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 8px;
  box-sizing: border-box;
  outline: none;
  transition: border-color 0.3s ease;
}

.input-group input:focus {
  border-color: #007bff;
  box-shadow: 0 0 0 3px rgba(0, 123, 255, 0.25);
}

.error-message {
  color: #e74c3c;
  font-size: 14px;
  margin-bottom: 15px;
  text-align: center;
}

.submit-button {
  width: 100%;
  padding: 14px;
  background-color: #006bb3;
  color: white;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 10px;
}

.submit-button:hover {
  background-color: #0056b3;
}

.submit-button:active {
  background-color: #003d7a;
}

@media (max-width: 480px) {
  .reset-password-container {
    padding: 15px;
  }

  .reset-password-container h2 {
    font-size: 20px;
  }

  .input-group input {
    padding: 10px;
    font-size: 13px;
  }

  .submit-button {
    font-size: 14px;
    padding: 12px;
  }
}

@media (max-width: 600px) {
  .reset-password-container {
    max-width: 500px;
  }

  .submit-button {
    font-size: 15px;
    padding: 12px 18px;
  }
}