/* General styles */
.coach-feedback-container {
    background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.header {
    font-size: 24px;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    justify-content: space-between;
   
}

.search-input {
    width: 20%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Table Styles */
.coach-feedback-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.coach-feedback-table thead {
    background-color: #001861;
}

.coach-feedback-table th {
    padding: 12px 15px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
}

.coach-feedback-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

/* Row Styles */
.coach-feedback-table tr:nth-child(even) {
    background-color: #f9f9f9;
}



button {
    padding: 8px 12px;
    margin: 5px;
    font-size: 14px;
    cursor: pointer;
    border-radius: 5px;
    border: none;
}

.edit-button {
    background-color: #4CAF50;
    color: white;
}

.delete-button {
    background-color: #f44336;
    color: white;
}

/* Modal Styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
}

.modal-content h2 {
    margin-bottom: 20px;
}

.modal-body {
    margin-bottom: 20px;
}

.modal-footer {
    display: flex;
    justify-content: space-around;
}

.modal-footer button {
    width: 100px;
}

.save-button {
    background-color: #4CAF50;
    color: white;
}

.cancel-button {
    background-color: #f44336;
    color: white;
}

.confirm-button {
    background-color: #4CAF50;
    color: white;
}
.create-coach-button {
    padding: 8px 16px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    
  }
  
  .create-coach-button:hover {
    background-color: #45a049;
  }