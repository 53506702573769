/* General container for SetGuardian component */
.sg-container {
    max-width: 850px;
    margin: 40px auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 6px 18px rgba(0, 0, 0, 0.1);
    font-family: 'Poppins', sans-serif;
  }
  
  .sg-page-title {
    font-size: 26px;
    font-weight: 600;
    margin-bottom: 30px;
    color: #2c3e50;
    text-align: center;
  }
  
  .sg-guardian-info {
    display: flex;
    flex-direction: column;
    gap: 12px;
  }
  
  .sg-guardian-info label {
    font-size: 16px;
    font-weight: 500;
    color: #34495e;
  }
  
  .sg-input {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 8px;
    background-color: #f9f9f9;
    color: #333;
   
  }
  
  .sg-guardian-selection {
    margin-top: 30px;
  }
  
  .sg-guardian-selection h3 {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .sg-guardian-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .sg-guardian-table th,
  .sg-guardian-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .sg-guardian-table th {
    background-color: #f2f2f2;
  }
  
  .sg-select-btn {
    padding: 8px 16px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sg-select-btn:hover {
    background-color: #2980b9;
  }
  
  .sg-action-buttons button {
    padding: 12px 24px;
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    margin-top: 20px;
    transition: background-color 0.3s ease;
  }
  
  .sg-action-buttons button:hover {
    background-color: #2980b9;
  }
  
  .sg-create-guardian-btn {
    background-color: #f39c12;
  }
  
  .sg-create-guardian-btn:hover {
    background-color: #e67e22;
  }
  
  .sg-guardian-entry-table {
    margin-top: 30px;
  }
  
  .sg-guardian-entry-table table {
    width: 100%;
    border-collapse: collapse;
  }
  
  .sg-guardian-entry-table th,
  .sg-guardian-entry-table td {
    padding: 12px;
    border: 1px solid #ddd;
    text-align: left;
  }
  
  .sg-submit-btn-container {
    display: flex;
    justify-content: center;
    margin-top: 40px;
    gap: 20px;
  }
  
  .sg-submit-btn {
    padding: 14px 30px;
    font-size: 18px;
    background-color: #2ecc71;
    color: white;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sg-submit-btn:hover {
    background-color: #27ae60;
  }
  
  /* Proceed to Sibling Button */
  .sg-proceed-sibling-btn {
    padding: 14px 30px;
    font-size: 18px;
    background-color: #e74c3c;
    color: white;
    text-decoration: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .sg-proceed-sibling-btn:hover {
    background-color: #c0392b;
  }
  
  /* Responsive Design */
  @media (max-width: 768px) {
    .sg-container {
      margin: 20px;
      padding: 20px;
    }
  
    .sg-page-title {
      font-size: 22px;
    }
  
    .sg-guardian-info label {
      font-size: 14px;
    }
  
    .sg-input {
      font-size: 14px;
    }
  
    .sg-guardian-table th, .sg-guardian-table td {
      font-size: 14px;
    }
  
    .sg-submit-btn {
      font-size: 16px;
    }
  
    .sg-proceed-sibling-btn {
      font-size: 16px;
    }
  }
  