/* Coupon List Container */
.coupon-list-container {
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    padding: 20px;
}
.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

}

.search-input {
    width: 20%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    box-sizing: border-box;
}

.create-button {
    padding: 12px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.create-button:hover {
    background-color: #45a049;
}

/* Coupon List Table */
.coupon-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
}

.coupon-table th,
.coupon-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
}

.coupon-table th {
    background-color: #00356B;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.coupon-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.coupon-table tr:hover {
    background-color: #f1f1f1;
}

.coupon-table td {
    vertical-align: middle;
}

/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 10px;
}

.edit-btn {
    background-color: green;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.edit-btn:hover {
    background-color: green;
}

.delete-btn {
    background-color: #ff4d4f;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
}

.delete-btn:hover {
    background-color: #d32f2f;
}

/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px 30px;
    border-radius: 10px;
    max-width: 800px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
    

}

.modal-content h2 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
}

.modal-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
}

.modal-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
}

.confirm-btn {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.confirm-btn:hover {
    background-color: #45a049;
}

.cancel-btn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
}

.cancel-btn:hover {
    background-color: #e53935;
}

.close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    color: #555;
    cursor: pointer;
}

.close-btn:hover {
    color: #000;
}

.checkbox {
    display: flex;
    align-items: center; /* Aligns checkbox and text vertically */
    gap: 7px; /* Adds space between the label and the checkbox */
    margin-left: 4px;
}

.checkbox input[type="checkbox"] {
    margin: 0; /* Removes default margin from checkbox */
    padding: 0; /* Removes padding */
    vertical-align: middle; /* Ensures the checkbox is aligned properly with the text */
    width: 10px;
}
/* Dropdown toggle button */
.dropdown-toggle-btn {
    background: transparent;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #444;
  }
  
  .dropdown-toggle-btn:hover {
    color: #007BFF; /* Highlight on hover */
  }
  
  /* Dropdown menu container */
  .dropdown-menu-container {
    position: absolute;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    width: 100px;
    text-align: left;
    display: flex;
    flex-direction: column;
    padding: 5px 0;
  }
  
  /* Dropdown menu item */
  .dropdown-item {
    background: none;
    border: none;
    text-align: left;
    padding: 8px 10px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
  }
  
  .dropdown-item:hover {
    color: #007BFF; /* Blue text on hover */
  }
  
 
  
  .dropdown-menu-container button{background: none;
    border: none;
    text-align: left;
    width: 100%;
    padding: 0px 15px;
    font-size: 0.9rem;
    cursor: pointer;
    color: #333;
    white-space: nowrap;
    transition: background-color 0.3s, color 0.3s;}
    
    @media (max-width: 768px) {
        /* Coupon List Container */
        .coupon-list-container {
          padding: 10px;
          margin: 5px;
          max-width: 100%;
        }
      
        /* Search Bar */
        .search-bar {
          flex-direction: column;
          align-items: stretch;
          gap: 8px;
        }
      
        .search-input {
          width: 100%;
          font-size: 14px;
        }
      
        .create-button {
          width: 100%;
          padding: 10px;
          font-size: 14px;
        }
      
        /* Coupon Table Container */
        .coupon-table-container {
          width: 100%;
          overflow-x: auto; /* Enables horizontal scrolling */
          margin-top: 10px;
        }
      
        /* Coupon Table */
        .coupon-table {
          width: 100%;
          font-size: 12px;  /* Adjust font size for readability */
          table-layout: fixed;  /* Forces the table cells to have equal width */
          border-collapse: collapse;
        }
      
        .coupon-table th,
        .coupon-table td {
          padding: 8px 10px; /* Moderate padding for better readability */
          text-align: center;
          word-wrap: break-word; /* Prevents overflow in cells */
          white-space: nowrap; /* Prevents text wrapping */
          overflow: hidden;
          text-overflow: ellipsis; /* Display ellipsis for overflowing text */
        }
      
        /* Adjusting Column Headers */
        .coupon-table th {
          font-size: 14px;  /* Slightly larger font size for headers */
          font-weight: bold;
          text-transform: uppercase;  /* Capitalize column headers */
        }
      
        /* Table Cells (Data) */
        .coupon-table td {
          font-size: 12px;  /* Smaller font size for content */
        }
      
        /* Action Buttons */
        .action-buttons {
          flex-direction: column;
          gap: 6px;
        }
      
        .edit-btn,
        .delete-btn {
          padding: 6px 8px;
          font-size: 10px;  /* Smaller font size for action buttons */
        }
      
        /* Pagination */
        .pagination {
          display: flex;
          justify-content: center;
          gap: 5px;
          flex-wrap: wrap;
        }
      
        .page-number {
          font-size: 12px;
          padding: 5px 8px;
        }
      
        .prev-button,
        .next-button {
          font-size: 12px;
          padding: 6px 10px;
        }
      
        /* Modal */
        .modal-content {
          padding: 12px;
          max-width: 90%;
        }
      
        .modal-actions {
          flex-direction: column;
          gap: 8px;
        }
      
        .confirm-btn,
        .cancel-btn {
          padding: 8px 12px;
          font-size: 12px;
        }
      
        /* Dropdown */
        .dropdown-menu-container {
          width: auto;
          right: 0;
        }
      
        .dropdown-toggle-btn {
          font-size: 16px;
        }
      
        .dropdown-menu-container button {
          font-size: 12px;
          padding: 6px 10px;
        }
      
        .dropdown-item {
          padding: 6px 8px;
        }
      
        /* Hide large columns for mobile (Optional) */
        /* If necessary, you can still hide some columns on mobile. Uncomment the lines below to hide them */
        /* 
        .coupon-table td:nth-child(9),  /* Description column */
        .coupon-table td:nth-child(10) { /* Action column */
          display: none;
        }
    }
 /* Modal Overlay */
.Modal__Overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  transition: opacity 0.3s ease-in-out;
}

/* Modal Content */
.Modal__Content {
  width: 100%;
  max-width: 500px;
  background-color: #fff;
  padding: 2rem;
  border-radius: 0.5rem;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
  transform: scale(0.95);
  transition: transform 0.3s ease-in-out;
}

/* Modal Heading */
.Modal__Content h2 {
  font-size: 1.5rem;
  font-weight: 600;
  color: #333;
  margin-bottom: 1rem;
  text-align: center;
}

/* Textarea */
.Modal__Content textarea {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1rem;
  resize: none;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
}

.Modal__Content textarea:focus {
  border-color: #007BFF;
  outline: none;
}

/* Status Dropdown */
.Modal__Content select {
  width: 100%;
  padding: 1rem;
  border: 1px solid #ccc;
  border-radius: 0.5rem;
  font-size: 1rem;
  box-sizing: border-box;
  transition: border-color 0.3s ease-in-out;
}

.Modal__Content select:focus {
  border-color: #007BFF;
  outline: none;
}

/* Button Container */
.Modal__Content .mt-6 {
  display: flex;
  justify-content: flex-end;
  gap: 1rem;
}

/* Button Styles */
.Modal__Content button {
  padding: 0.75rem 1.5rem;
  font-size: 1rem;
  border-radius: 0.5rem;
  cursor: pointer;
  transition: background-color 0.3s ease-in-out;
  border: none;
  text-align: center;
}

.Modal__Content button:hover {
  opacity: 0.85;
}

/* Cancel Button */
.Modal__Content .cancel-btn {
  background-color: #e0e0e0;
  color: #333;
}

.Modal__Content .cancel-btn:hover {
  background-color: #c5c5c5;
}

/* Save/Update Button */
.Modal__Content .save-btn {
  background-color: #007BFF;
  color: white;
}

.Modal__Content .save-btn:hover {
  background-color: #0056b3;
}

/* Responsiveness */
@media screen and (max-width: 600px) {
  .Modal__Content {
    padding: 1.5rem;
    width: 90%;
  }
}
/* Modal Overlay */
.update-status-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 1000;
}

/* Modal Content */
.update-status-modal {
  background-color: white;
  padding: 20px;
  max-width: 500px;
  width: 100%;
  border-radius: 8px;
  position: relative;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 1001;
}

/* Modal Title */
.modal-title {
  text-align: center;
  font-size: 18px;
  margin-bottom: 20px;
  font-weight: bold;
}

/* Textarea (Remarks) */
.remarks-textarea {
  width: 95%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  resize: vertical;
  min-height: 80px;
}

/* Status Dropdown */
.status-dropdown {
  width: 100%;
  padding: 10px;
  font-size: 14px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 20px;
}

/* Modal Actions */
.modal-actions {
  display: flex;
  justify-content: space-between;
}

/* Save Button */
.save-button {
  background-color: #4CAF50;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
  margin-right: 10px;
}

.save-button:hover {
  background-color: #45a049;
}

.save-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}

/* Cancel Button */
.cancel-button {
  background-color: #f44336;
  color: white;
  border: none;
  padding: 10px 15px;
  font-size: 14px;
  border-radius: 4px;
  cursor: pointer;
  flex: 1;
}

.cancel-button:hover {
  background-color: #e53935;
}

.cancel-button:disabled {
  background-color: #cccccc;
  cursor: not-allowed;
}
/* Overall container for all charts */
.unique-charts-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  padding: 0px;
  padding-top: 10px;
  background-color: #f4f4f9;
}

/* Styling for each individual chart container */
.unique-chart-container {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 24%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.unique-charts-container {
  display: flex;
  justify-content: space-around;
  gap: 10px;
  padding: 0px;
  padding-top: 10px;
  background-color: #f4f4f9;
}
.unique-charts-query{
  display: flex;
  gap: 10px;
  padding: 0px;
  padding-top: 10px;
  background-color: #f4f4f9;
  overflow-x: auto; /* Enable horizontal scrolling */
  overflow-y: hidden; 
}
.unique-chart-dropoffs {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 25%;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  transition: transform 0.3s ease-in-out;
}
.unique-chart-query {
  background-color: white;
  border: 1px solid #ddd;
  border-radius: 8px;
  width: 19%;
  padding: 10px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
  align-content: center;
}
/* Responsive styling */
@media (max-width: 768px) {
  .unique-charts-container {
    flex-direction: column;
    align-items: center;
  }
  
  .unique-chart-container {
    width: 80%;
  }
}
.items-container {
  display: flex;
  justify-content: space-between; /* Adds space between elements */
  align-items: center; /* Optional: vertically center elements */
}.upload-csv-input {
  display: inline-block;
  background-color: #4CAF50;
  color: white;
  padding: 10px 20px;
  cursor: pointer;
}
.status-query {
  margin: 0;
  padding: 0;
}
.unique-charts-query {
  max-width: 1200px;
  overflow-x: scroll;
}
.download-csv-link {
  margin-top: 10px;
  margin-left: 15px;
  display: inline-block;
  color: blue;
  text-decoration: underline;
  font-weight: bold;
  cursor: pointer;
}
