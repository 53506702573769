/* General styles */
.academy-feedback-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.header {
    font-size: 24px;
    margin-bottom: 20px;
}

.search-bar {
    display: flex;
    justify-content: space-between;

}

.search-input {
    width: 20%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
}

/* Table Styles */
.academy-feedback-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.academy-feedback-table thead {
    background-color: #001861;
}

.academy-feedback-table th {
    padding: 12px 15px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
}

.academy-feedback-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

/* Row Styles */
.academy-feedback-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Button Style */
.edit-button {
    padding: 5px 10px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.edit-button:hover {
    background-color: #45a049;
}
/* Modal Overlay */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    width: 400px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .modal-body label {
    font-size: 14px;
    display: block;
    margin-bottom: 5px;
  }
  
  .modal-body input,
  .modal-body textarea {
    width: 100%;
    padding: 8px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 5px;
  }
  
  .modal-footer {
    display: flex;
    justify-content: space-between;
  }
  
  .save-button,
  .cancel-button {
    padding: 10px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  .cancel-button {
    background-color: #f44336;
  }
  
  .cancel-button:hover {
    background-color: #e53935;
  }
  