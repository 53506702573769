.booking-container-confirmation {
    padding: 10px;
    background: #fff;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    border-radius: 12px;
    font-family: 'Arial', sans-serif;
}

.booking-title {
    text-align: center;
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 15px;
}

.booking-section,
.booking-section-court {
    background: #f9f9f9;
    padding: 15px;
    margin-bottom: 12px;
    border-radius: 8px;
}

.section-title {
    font-size: 18px;
    font-weight: bold;
    margin-bottom: 10px;
    color: #222;
}

.court-item {
    background: #fff;
    padding: 10px;
    border-radius: 8px;
    margin-bottom: 8px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
}

.court-name {
    font-size: 16px;
    font-weight: bold;
    margin-bottom: 5px;
}

.timeslot-list {
    list-style-type: none;
    padding: 0;
    display: flex;
    flex-wrap: wrap;
    gap: 8px;
}

.timeslot {
    background: #e3f2fd;
    padding: 6px 12px;
    border-radius: 6px;
    font-size: 14px;
    font-weight: 500;
}

.amount,
.total-amount {
    font-size: 16px;
    display: flex;
    justify-content: space-between;
    font-weight: 500;
}

.total-amount {
    font-weight: bold;
  
}

.user-details-section {
    background: #f9f9f9;
    padding: 15px;
    border-radius: 8px;
}

.user-input-container {
    display: flex;
    flex-direction: column;
    gap: 12px;
}

.input-group-court {
    display: flex;
    flex-direction: column;
}

.user-label {
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 5px;
}

.user-input {
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 6px;
    font-size: 14px;
}

.collection-option {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin: 5px 0;
    cursor: pointer;
}

.radio-input {
    margin-right: 8px;
}

.confirm-booking-btn {
    width: 100%;
    padding: 12px;
    font-size: 18px;
    font-weight: bold;
    color: #fff;
    background: #28a745;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: all 0.3s ease;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.1);
}

.confirm-booking-btn:hover {
    background: #218838;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.15);
}

@media (max-width: 480px) {
    .user-input-container {
        flex-direction: column;
    }
}