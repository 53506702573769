/* General Styles */
.payment-list-container {
    padding: 3px;
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin: 0px auto;
  }

  .payment-list-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
  }
  
  .payment-list-table thead {
    background-color: #003366;
  }
  
  .payment-list-table th {
    padding: 15px 20px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
    text-align: center;
  }
  
  .payment-list-table td {
    padding: 15px 20px;
    font-size: 16px;
    vertical-align: middle;
    color: #333333;
    border: 1px solid #ddd;
  }
  
  /* Row Styles */
  .payment-list-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  /* Button Styles */
  .edit-button,
  .delete-button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px;
    color: white;
  }
  
  .edit-button {
    background-color: #007bff;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #a71d2a;
  }
  
  /* Scroll Container Styles */
  .payment-list-scroll-container {
    
    overflow-y: auto;
    padding-top: 10px;
  }
  
  /* Scrollbar Customization */
  .payment-list-scroll-container::-webkit-scrollbar {
    width: 10px;
    height: 8px;
  }
  
  .payment-list-scroll-container::-webkit-scrollbar-thumb {
    background-color: #001861;
    
    border-radius: 10px;
  }
  
  .payment-list-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #004aad;
  }
  /* Search and Create Container */
.search-create-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-input {
 
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .create-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .create-button:hover {
    background-color: #218838;
  }
  .filter-container {
    display: flex;
    flex-wrap: wrap;
    gap: 19px;
    padding: 20px;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin-bottom: 20px;
  }.filter-container select,
  .filter-container input[type="date"] {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 4px;
    min-width: 150px;
  }

  .payment-list-scroll-container {
    max-width: 1130px;
    overflow-y: auto;
  }