/* Cancelled Batch List Container */
.cancel-batch-container {
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
  }
  /* Search Bar */
  .search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;

  }
  
  .search-input {
    width: 20%;
    padding: 10px 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    box-sizing: border-box;
  }
  
  .create-button {
    padding: 12px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .create-button:hover {
    background-color: #45a049;
  }
  
  /* Cancelled Batch List Table */
  .cancel-batch-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
  }
  
  .cancel-batch-table th,
  .cancel-batch-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    text-align: left;
    font-size: 14px;
  }
  
  .cancel-batch-table th {
    background-color: #003b72;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
  }
  
  .cancel-batch-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .cancel-batch-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .cancel-batch-table td {
    vertical-align: middle;
  }
  
  /* Notification Tag */
  .notification-tag {
    display: inline-block;
    padding: 5px 10px;
    border-radius: 20px;
    font-size: 12px;
    text-transform: capitalize;
  }
  
  .notification-active {
    background-color: #4caf50;
    color: white;
  }
  
  .notification-inactive {
    background-color: #ff4d4f;
    color: white;
  }
  
  /* Action Buttons */
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .edit-btn {
    background-color: green;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .edit-btn:hover {
    background-color: green;
  }
  
  .delete-btn {
    background-color: #ff4d4f;
    border: none;
    color: white;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
    font-size: 14px;
  }
  
  .delete-btn:hover {
    background-color: #d32f2f;
  }
  
  /* Modal Overlay */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.6);
    display: flex;
    align-items: center;
    justify-content: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px 30px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    text-align: center;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.3);
  }
  
  .modal-content h2 {
    font-size: 20px;
    margin-bottom: 15px;
    color: #333;
  }
  
  .modal-content p {
    font-size: 16px;
    color: #555;
    margin-bottom: 20px;
  }
  
  .modal-actions {
    display: flex;
    gap: 10px;
    justify-content: center;
  }
  
  .confirm-btn {
    padding: 10px 20px;
    background-color: #4caf50;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .confirm-btn:hover {
    background-color: #45a049;
  }
  
  .cancel-btn {
    padding: 10px 20px;
    background-color: #f44336;
    color: white;
    border: none;
    border-radius: 6px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .cancel-btn:hover {
    background-color: #e53935;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 15px;
    background: none;
    border: none;
    font-size: 20px;
    color: #555;
    cursor: pointer;
  }
  
  .close-btn:hover {
    color: #000;
  }
  
  /* Checkbox styling */
  .checkbox {
    display: flex;
    align-items: center;
    gap: 7px;
    margin-left: 4px;
  }
  
  .checkbox input[type="checkbox"] {
    margin: 0;
    padding: 0;
    vertical-align: middle;
    width: 10px;
  }
  
  /* Pagination */
  .pagination {
    display: flex;
    justify-content: end;
    gap: 8px;
    margin: 20px 0;
  }
  
  .prev-button,
  .next-button {
    background-color: #003b72;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-bottom: 15px;
  }
  
  .prev-button:disabled,
  .next-button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
  
  .page-number {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    padding: 8px 12px;
    font-size: 11px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    height: 30px;
  }
  
  .page-number.active {
    background-color: #007bff;
    color: white;
  }
  
  .page-number:focus {
    outline: none;
  }
  
  /* Ellipsis button for pagination */
  .ellipsis-button {
    background: none;
    border: none;
    font-size: 18px;
    cursor: pointer;
    color: #555;
    padding: 8px;
    transition: color 0.3s;
  }
  
  .ellipsis-button:hover {
    color: #007bff;
  }
  
  /* Custom dropdown for actions */
  .custom-dropdown {
    position: absolute;
    top: 70%;
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 4px;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1);
    padding: 8px 0;
    z-index: 1000;
    width: 150px;
    display: flex;
    flex-direction: column;
    right: 0;
  }
  
  .custom-dropdown button {
    background: none;
    border: none;
    text-align: left;
    padding: 0px 16px;
    font-size: 14px;
    color: #333;
    cursor: pointer;
    width: 90%;
  }
  
  .custom-dropdown button:hover {
    background-color: #f8f9fa;
    color: #007bff;
  }
  
  /* Positioning of action buttons for dropdown */
  .action-buttons {
    position: relative;
  }
  