.new-batch-container {
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
  }
  
  .title {
    text-align: center;
    font-size: 1.75rem;
    color: #001861;
    margin-bottom: 20px;
  }
  
  .new-batch-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .form-label {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .form-input, .form-select, .batch-time-input, .routine-select {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ccc;
    border-radius: 6px;
    transition: all 0.3s ease;
  }
  
  .form-input:focus, .form-select:focus, .batch-time-input:focus, .routine-select:focus {
    border-color: #007BFF;
    outline: none;
  }
  
  .time-select-container {
    display: flex;
    justify-content: space-between;
    gap: 10px;
  }
  
  .checkbox-label {
    font-size: 1rem;
    font-weight: 600;
  }
  
  .checkbox-input {
    margin-left: 10px;
  }
  
  .submit-btn {
    padding: 12px;
    background-color: #4CAF50;
    color: white;
    font-size: 1.1rem;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
  }
  
  .batch-timings-table, .session-plans-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .batch-timings-table th, .session-plans-table th {
    background-color: #f0f0f0;
    padding: 12px;
    font-weight: 600;
  }
  
  .batch-timings-table td, .session-plans-table td {
    padding: 12px;
    text-align: center;
  }
  
  .day-checkbox {
    width: 20px;
    height: 20px;
  }
  
  .section-title {
    font-size: 1.4rem;
    font-weight: 600;
    margin: 0px;
  }
  .batch-timings-table, .session-plans-table {
    width: 100%;
    border-collapse: collapse;  /* Ensures borders between cells are merged */
    margin-bottom: 20px;
  }
  
  .batch-timings-table th, .session-plans-table th {
    background-color: #f0f0f0;
    padding: 12px;
    font-weight: 600;
    border: 1px solid #ccc; /* Border for header cells */
  }
  
  .batch-timings-table td, .session-plans-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ccc; /* Border for table cells */
  }
  
  .batch-timings-table tr:nth-child(even), .session-plans-table tr:nth-child(even) {
    background-color: #f9f9f9; /* Light gray background for even rows */
  }
  
  .batch-timings-table tr:hover, .session-plans-table tr:hover {
    background-color: #f1f1f1; /* Hover effect for rows */
  }
  
  .batch-timings-table td, .session-plans-table td {
    text-align: center;
  }
  
  .batch-timings-table th, .session-plans-table th {
    background-color: #f0f0f0;
    color: #333;
  }
  