.shop-container {
    width: 100%;
    max-width: 1100px;
    margin: 50px auto;
    padding: 20px;
    background-color:#f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 20px rgba(0, 0, 0, 0.1);
    /* font-family: 'Segoe UI', Tahoma, Geneva, Verdana, sans-serif; */
  }
  

  .shop-title {
    font-size: 30px;
    margin-bottom: 20px;
    color: #001861;
    text-align: center;
  }
  

  .shop-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  

  .form-group-player-name,
  .form-group-reward-points-balance,
  .form-group-transaction-type,
  .form-group-reward-points,
  .form-group-submit-btn {
    display: flex;
    flex-direction: column;
  }
  
 
  .form-label-player-name,
  .form-label-reward-points-balance,
  .form-label-transaction-type,
  .form-label-reward-points {
    font-size: 14px;
    color: #666;
    font-weight: 500;
    margin-bottom: 8px;
    transition: color 0.3s ease;
  }
  
 
  .form-input-player-name,
  .form-input-reward-points-balance,
  .form-input-transaction-type,
  .form-input-reward-points {
    width: 100%;
    padding: 14px;
    font-size: 16px;
    border: 1px solid #ddd;
    border-radius: 6px;
    box-sizing: border-box;
    transition: border-color 0.3s ease, background-color 0.3s ease;
  }
  

  .form-input-player-name:focus,
  .form-input-reward-points-balance:focus,
  .form-input-transaction-type:focus,
  .form-input-reward-points:focus {
    outline: none;
    border-color: #0056b3;
    background-color: #f2f7ff;
  }
  
 
  .form-input-player-name[disabled],
  .form-input-reward-points-balance[disabled] {
    background-color: #f2f2f2;
    color: #6c757d;
    cursor: not-allowed;
    border-color: #ddd;
  }
  

  .form-submit-btn {
    width: 100%;
    padding: 15px;
    font-size: 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 6px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.2s ease;
  }
  
  .form-submit-btn:hover {
    background-color: #0056b3;
    transform: translateY(-2px);
  }
  
  .form-submit-btn:active {
    background-color: #004085;
    transform: translateY(0);
  }
  

  .shop-container .form-group {
    margin-bottom: 25px;
  }
  

  .form-input-player-name:focus,
  .form-input-reward-points-balance:focus,
  .form-input-transaction-type:focus,
  .form-input-reward-points:focus {
    box-shadow: 0 0 8px rgba(0, 87, 179, 0.2);
  }
  

  .form-message {
    font-size: 14px;
    color: #e74c3c;
    text-align: center;
    margin-top: 15px;
  }
  