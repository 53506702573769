/* Wrapper for the whole page */
.new-player-wrapper {
  background: #f4f6f9;
  padding: 30px;
  border-radius: 10px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  max-width: 1000px;
  margin: 5px auto;
  animation: fadeInUp 1s ease-out;
}


/* Form container */
.new-player-form {
  display: flex;
  flex-direction: column;
  gap: 18px;
}

/* Container for the input field and label */
.input-container {
  display: flex;
  flex-direction: column;
  gap: 6px;
}

.mobile-label {
  font-size: 15px;
  color: #444;
  font-weight: 500;
}

/* Mobile input field style */
.mobile-input-field {
  padding: 12px;
  font-size: 16px;
  border: 1px solid #d1d5db;
  border-radius: 8px;
  outline: none;
  transition: all 0.3s ease-in-out;
}

.mobile-input-field:focus {
  border-color: #4c9bf7;
  box-shadow: 0 0 8px rgba(76, 155, 247, 0.2);
}

/* Submit button styling */
.submit-search-button {
  background: #004085;
  color: white;
  font-size: 16px;
  padding: 14px 22px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s;
}

/* Animation for smooth page load */
@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translateY(30px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}
/* Style for the link when acting as a button */
.create-new-player-button {
  display: inline-block;
  padding: 10px 20px;
  background-color: #007bff; /* Button background color */
  color: white; /* Text color */
  font-size: 16px; /* Font size */
  font-weight: bold;
  border: none;
  border-radius: 5px; /* Rounded corners */
  text-align: center;
  text-decoration: none; /* Remove underline from the link */
  cursor: pointer; /* Change cursor to pointer */
  transition: background-color 0.3s ease, transform 0.2s ease;
}

/* Hover effect for the button */
.create-new-player-button:hover {
  background-color: #0056b3; /* Darker shade of blue */
  transform: translateY(-2px); /* Lift the button slightly */
}

/* Focus effect for accessibility */
.create-new-player-button:focus {
  outline: none; /* Remove default focus outline */
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.5); /* Custom focus ring */
}
/* Player Details Container Styling */
.player-details {
  width: 100%;
  max-width: 900px;
  background-color: #ffffff; /* White background */
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Light shadow for depth */
  padding: 25px;
  margin: 20px auto; /* Center the container */
  font-family: 'Poppins', sans-serif;
  color: #333;
}

/* Heading Styling */
.player-details h3 {
  font-size: 28px;
  font-weight: 600;
  color: #003366; /* Dark blue color for heading */
  margin-bottom: 20px;
  text-transform: uppercase;
  border-bottom: 2px solid #003366; /* Underline to match heading color */
  padding-bottom: 10px;
}

/* Table Styling */
.player-details table {
  width: 100%;
  border-collapse: collapse; /* Collapsing table borders for clean look */
  margin-top: 20px;
}

/* Table Header Styling */
.player-details thead {
  background-color: #003366; /* Dark blue background */
}

.player-details th {
  padding: 12px 15px;
  color: white;
  text-transform: uppercase;
  text-align: left;
  font-size: 16px;
  font-weight: 600;
  border: 1px solid #ddd; /* Light border around cells */
  border-radius: 8px 8px 0 0;
}

/* Table Body Styling */
.player-details td {
  padding: 12px 15px;
  font-size: 14px;
  color: #333;
  vertical-align: middle;
  border: 1px solid #ddd; /* Light border between cells */
  text-align: left;
}

/* Row Styling - Alternate Row Colors */
.player-details tr:nth-child(even) {
  background-color: #f9f9f9; /* Light gray for even rows */
}


/* Action Button Styling */
.player-details .action-button {
  padding: 8px 16px;
  background-color: #007BFF; /* Blue button */
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 14px;
  text-transform: uppercase;
  display: inline-block;
  margin-top: 15px;
  text-align: center;
  transition: background-color 0.3s ease, transform 0.3s ease;
}

/* Action Button Click Effect */
.player-details .action-button:active {
  transform: translateY(0); /* Reset the lift effect */
  background-color: #004085; /* Even darker blue when clicked */
}


/* Style for the select-coach-button */
.select-coach-button {
  background-color: #4CAF50;  /* Green background */
  color: white;  /* White text */
  border: none;  /* Remove default border */
  padding: 10px 20px;  /* Add padding */
  text-align: center;  /* Center align text */
  text-decoration: none;  /* Remove underline from the text */
  display: inline-block;  /* Make button behave like an inline element */
  font-size: 16px;  /* Text size */
  border-radius: 5px;  /* Rounded corners */
  cursor: pointer;  /* Pointer cursor on hover */
  transition: background-color 0.3s ease, transform 0.2s ease;  /* Smooth transition for background and transform */
}

.select-coach-button:hover {
  background-color: #45a049;  /* Slightly darker green on hover */
}

/* Action Links - Display each link on a separate line */
.action-links {
  display: flex;
  flex-direction: column; /* Stack the links vertically */
  gap: 10px; /* Add some spacing between the links */
}

.action-link {
  color: #3498db;
  text-decoration: none;
  padding: 8px 15px;
  border: 1px solid #3498db;
  border-radius: 6px;
  transition: all 0.3s ease;
  display: block; /* Make sure each link is block-level */
}

.action-link:hover {
  background-color: #3498db;
  color: #fff;
  box-shadow: 0 4px 10px rgba(52, 152, 219, 0.2);
}
