/* General styles */
.academy-player-list-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  }
  
  .header {
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .search-bar {
    display: flex;
    justify-content: space-between;

  }
  
  .search-input {
    width: 20%;
    padding: 8px;
    border-radius: 5px;
    border: 1px solid #ddd;
  }
  
  /* Table Styles */
  .player-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    margin: 5px auto;
    
  }
  
  .player-table thead {
    background-color: #003366;
  }
  
  .player-table th {
    padding: 12px 15px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .player-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
  
  /* Row Styles */
  .player-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .action-buttons {
    text-align: center;
  }
  
  .dots-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
  }
  
  .player-table tr:hover .dropdown-menu {
    display: block;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .close-btn {
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
    position: absolute;
    top: 10px;
    right: 10px;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .confirm-btn,
  .cancel-btn {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-btn {
    background-color: #007bff;
    color: white;
  }
  
  .cancel-btn {
    background-color: #ddd;
    color: black;
  }
  
  .checkbox {
    margin: 10px 0;
  }
 
  
  .dots-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
  }
  .dropdown-menu-player {
    position: absolute;
    top: 50px;
    right: 0;
    background-color: white;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 150px;
    padding: 5px 0;
    display: flex;
    flex-direction: column;
  }

.custom-dropdown-player {
  position: absolute;
  right: 20px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  width: auto;
  text-align: left;
  display: flex;
  flex-direction: column;
  padding: 5px 0;
}
