/* Container Styling */
.create-coach-container {
    max-width: 1200px;
    padding: 20px;
    background-color: #f4f6f9;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 8px;
  }
  
  /* Title Styling */
  .create-coach-title {
    text-align: center;
    color: #001861;
    font-size: 24px;
    font-weight: bold;
    margin-bottom: 20px;
  }
  
  /* Form Styling */
  .create-coach-form {
    display: flex;
    flex-direction: column;
    gap: 15px;
  }
  
  /* Form Group */
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  /* Label Styling */
  .form-label {
    margin-bottom: 5px;
    font-size: 14px;
    font-weight: 500;
  }
  
  /* Input and Textarea Styling */
  .form-input,
  .form-textarea,
  .form-select {
    width: 100%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ccc;
    border-radius: 5px;
    transition: border-color 0.3s ease;
    box-sizing: border-box;
  }
  
  .form-textarea {
    resize: vertical;
    min-height: 80px;
  }
  
  /* File Input Styling */
  .form-file-input {
    padding: 5px;
    border: none;
    font-size: 14px;
  }
  
  /* Input Focus Effect */
  .form-input:focus,
  .form-textarea:focus,
  .form-select:focus {
    border-color: #007bff;
    outline: none;
  }
  
  /* Button Styling */
  .form-submit-button {
    width: 100%;
    padding: 12px;
    background-color: #001861;
    color: white;
    font-size: 16px;
    font-weight: bold;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .form-submit-button:hover {
    background-color: #0056b3;
  }
  
  