/* General Styles */
.plan-list-container {
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding:6px;
  }
  
  .header {
    font-size: 24px;
    font-weight: bold;
    color: #001861;
    margin-bottom: 20px;
    text-align: center;
  }
  
  /* Table Styles */
  .plan-list-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .plan-list-table thead {
    background-color: #001861;
  }
  
  .plan-list-table th {
    padding: 15px 20px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
  }
  
  .plan-list-table td {
    padding: 15px 20px;
    font-size: 16px;
    vertical-align: middle;
    color: #333333;
    border: 1px solid #ddd;
  }
  
  /* Row Styles */
  .plan-list-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  

  /* Button Styles */
  .edit-button,
  .delete-button {
    padding: 8px 15px;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
    margin-right: 5px;
    color: white;
  }
  
  .edit-button {
    background-color: #007bff;
  }
  
  .edit-button:hover {
    background-color: #0056b3;
  }
  
  .delete-button {
    background-color: #dc3545;
  }
  
  .delete-button:hover {
    background-color: #a71d2a;
  }
  
  /* Scroll Container Styles */
  .plan-list-scroll-container {
    max-height: 500px; /* Adjust as needed for your layout */
    overflow-y: auto;
    padding-right: 10px;
  }
  
  /* Scrollbar Customization */
  .plan-list-scroll-container::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }
  
  .plan-list-scroll-container::-webkit-scrollbar-thumb {
    background-color: #001861;
    border-radius: 10px;
  }
  
  .plan-list-scroll-container::-webkit-scrollbar-thumb:hover {
    background-color: #004aad;
  }
  /* Search and Create Container */
.search-create-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  
  .search-input {
    padding: 10px 15px;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 16px;
  }
  
  .create-button {
    padding: 10px 20px;
    font-size: 16px;
    font-weight: bold;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 200px;
  }
  
  .create-button:hover {
    background-color: #218838;
  }
  /* PlanList.css */
.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-content {
  background: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
}

.form-group {
  margin-bottom: 10px;
}

.form-group label {
  display: block;
  font-weight: bold;
}

.form-group input {
  width: 100%;
  padding: 5px;
  margin-top: 5px;
  height: 30px;
}

.save-button, .cancel-button, .delete-button {
  margin-right: 10px;
  padding: 10px 20px;
  cursor: pointer;
}
.plan-action-wrapper {
  position: relative;
}

.plan-dots-button {
  background: transparent;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.plan-dropdown-menu {
  position: absolute;
  top: 0%; /* Positions the dropdown above the button */
  right: 0;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
  z-index: 1000;
  display: flex;
  flex-direction: column;
  padding: 10px 0;
}

.plan-dropdown-item {
  background-color: #fff;
  padding: 8px 16px;
  text-align: left;
  border: none;
  cursor: pointer;
  font-size: 14px;
  color: #333;
  transition: background-color 0.2s ease;
}

.plan-dropdown-item:hover {
  background-color: #f1f1f1;
}

.plan-dropdown-item:focus {
  outline: none;
}

.plan-dots-button:focus + .plan-dropdown-menu,
.plan-dropdown-menu:hover {
  display: block; /* Ensures the dropdown menu remains visible on hover */
}
@media (max-width: 768px) {
  .create-button{
    width: 100%;
  }
}