/* General Styles */
.coach-list-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 20px;
  }

  /* Search Bar Styles */
  .search-bar {
    display: flex;
    justify-content: space-between;
  }
  
  .search-input {
    width: 30%;
    padding: 10px;
    border: 1px solid #ddd;
    border-radius: 5px;
    font-size: 14px;
  }
  
  .create-button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    cursor: pointer;
  }
  
  .create-button:hover {
    background-color: #0056b3;
  }
  
  /* Table Styles */
  .coach-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
  }
  
  .coach-table thead {
    background-color: #003366;
  }
  
  .coach-table th {
    padding: 12px 15px;
    font-weight: bold;
    color: white;
    text-align: left;
    text-transform: uppercase;
    
  }
  
  .coach-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
  }
  
  /* Row Hover and Alternate Styles */
  .coach-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  

  /* Action Buttons */
  .action-buttons {
    text-align: center;
  }
  
  .edit-btn,
  .delete-btn {
    padding: 6px 12px;
    font-size: 12px;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .edit-btn {
    background-color: #28a745;
  }
  
  .edit-btn:hover {
    background-color: #218838;
  }
  
  .delete-btn {
    background-color: #dc3545;
  }
  
  .delete-btn:hover {
    background-color: #c82333;
  }
  
  /* Modal Styles */
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }
  
  .modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    max-width: 500px;
    width: 100%;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    position: relative;
  }
  
  .close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    cursor: pointer;
  }
  
  .modal-actions {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
  }
  
  .confirm-btn,
  .cancel-btn {
    padding: 10px 20px;
    font-size: 14px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  
  .confirm-btn {
    background-color: #007bff;
    color: white;
  }
  
  .cancel-btn {
    background-color: #ddd;
    color: black;
  }
  
  /* Dropdown Menu */
  .dots-btn {
    background: none;
    border: none;
    font-size: 20px;
    cursor: pointer;
    padding: 5px;
  }
  
  .dropdown-menu {
    position: absolute;
    top: 100%;
    right: 0;
    background-color: white;
    border: 1px solid #ddd;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 10px;
    display: none;
    width: 95%;
    
  }
  
  .dropdown-menu button {
    display: block;
    width: 100%;
    padding: 8px 10px;
    background: none;
    border: none;
    text-align: left;
    cursor: pointer;
  }
  
  .dropdown-menu button:hover {
    background-color: #f0f0f0;
  }
  
  /* Dropdown Toggle */
  .dots-btn.active + .dropdown-menu {
    display: block;
  }
  
  /* Checkbox */
  .checkbox {
    margin-top: 10px;
    font-size: 14px;
  }
  