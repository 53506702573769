/* Batch Cancellation Container */
.batch-cancellation-container {
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1100px;
    width: 100%;
    box-sizing: border-box;
  }
  
  h2 {
    text-align: center;
    font-size: 26px;
    color: #333;
    margin-bottom: 20px;
  }
  
  /* Checkbox and Label Styling */
  .checkbox-container {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-bottom: 20px;
  }
  
  .checkbox-container input[type="checkbox"] {
    width: 18px;
    height: 18px;
    cursor: pointer;
  }
  
  .checkbox-container label {
    font-size: 16px;
    color: #333;
  }
  
  /* Date Range Container */
  .date-range-container {
    display: flex;
    flex-direction: column;
    gap: 18px;
    margin-bottom: 20px;
  }
  
  /* Date Inputs Styling */
  .date-inputs {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 10px;
  }
  
  .date-inputs label {
    font-size: 16px;
    color: #333;
    min-width: 100px; /* Ensures labels have consistent width */
  }
  
  .date-inputs input[type="date"] {
    padding: 8px 12px;
    font-size: 14px;
    border-radius: 6px;
    border: 1px solid #ccc;
    width: 250px;
    outline: none;
    transition: border-color 0.3s ease;
  }
  
  .date-inputs input[type="date"]:focus {
    border-color: #007bff;
  }
  
  /* List Batches Button */
  .list-batches-button {
    background-color: #4caf50;
    color: white;
    padding: 14px 20px;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .list-batches-button:hover {
    background-color: #45a049;
  }
  
  .list-batches-button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
  
  /* Batch List Styling */
  .batch-list {
    margin-top: 20px;
  }
  
  .batch-list h3 {
    font-size: 20px;
    color: #333;
    margin-bottom: 15px;
  }
  
  .batch-list ul {
    list-style: none;
    padding-left: 0;
  }
  
  .batch-list li {
    background-color: #f8f8f8;
    padding: 12px;
    margin: 10px 0;
    border-radius: 6px;
    font-size: 16px;
    color: #333;
    display: flex;
    justify-content: space-between;
  }
  
  .batch-list li:nth-child(even) {
    background-color: #f0f0f0;
  }
  
  .batch-list li:hover {
    background-color: #f1f1f1;
  }
  
