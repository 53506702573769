.loading-spinner {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 24px;
    font-weight: bold;
    color: #ffffff;
    letter-spacing: 3px;
    text-transform: uppercase;
    font-family: 'Poppins', sans-serif;
    text-shadow: 0 4px 8px rgba(0, 0, 0, 0.3);
    animation: textMove 3s ease-in-out infinite;
}

@keyframes textMove {
    0% {
        transform: translateY(0);
        opacity: 1;
    }
    50% {
        transform: translateY(-10px);
        opacity: 0.7;
    }
    100% {
        transform: translateY(0);
        opacity: 1;
    }
}

.spinner {
    position: absolute;
    width: 60px;
    height: 60px;
    border-radius: 50%;
    border: 6px solid transparent;
    border-top: 6px solid #3399ff; 
    border-bottom: 6px solid #006bb3;
    animation: rotateSpinner 1.5s linear infinite, pulseEffect 2s ease-out infinite;
}

@keyframes rotateSpinner {
    0% {
        transform: rotate(0deg);
    }
    100% {
        transform: rotate(360deg);
    }
}

@keyframes pulseEffect {
    0%, 100% {
        transform: scale(1);
        opacity: 1;
    }
    50% {
        transform: scale(1.2);
        opacity: 0.7;
    }
}

.spinner::before {
    content: '';
    position: absolute;
    top: -10px;
    left: -10px;
    width: 80px;
    height: 80px;
    border-radius: 50%;
    border: 3px solid #ffffff;
    opacity: 0.5;
    animation: glowEffect 2s infinite alternate;
}

@keyframes glowEffect {
    0% {
        box-shadow: 0 0 15px rgba(51, 153, 255, 0.7), 0 0 25px rgba(51, 153, 255, 0.6);
    }
    100% {
        box-shadow: 0 0 30px rgba(51, 153, 255, 0.7), 0 0 50px rgba(51, 153, 255, 0.6);
    }
}

.spinner::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background: radial-gradient(circle, #3399ff, #006bb3); 
    animation: sparkleEffect 1.5s ease-in-out infinite;
    transform: translate(-50%, -50%);
}

@keyframes sparkleEffect {
    0% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0.5;
    }
    50% {
        transform: translate(-50%, -50%) scale(1.2);
        opacity: 1;
    }
    100% {
        transform: translate(-50%, -50%) scale(0.8);
        opacity: 0.5;
    }
}
/* Remove border from select box */
.react-select__control {
    border: none !important;
    box-shadow: none !important;
    background-color: transparent !important; /* Ensure a clean look */
  }
  
  /* Remove border on hover and focus */
  .react-select__control:hover {
    border: none !important;
  }
  
  .react-select__control--is-focused {
    border: none !important;
    box-shadow: none !important;
  }
  
  /* Change dropdown option hover color */
  .react-select__option {
    background-color: white !important;
    color: black !important;
  }
  
  .react-select__option--is-focused {
    background-color: #006bb3 !important;
    color: white !important;
  }
  
  .react-select__indicator {
    color: black !important;
  }

  .react-select__menu {
    border: none !important;
    box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1) !important;
  }
  .sales-pdf-cell {
    padding: 12px;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    border-bottom: 1px solid #ddd;
  }
  
  .pdf-button {
    display: inline-block;
    padding: 8px 14px;
    background-color: #006bb3;
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    text-align: center;
    text-decoration: none;
    border-radius: 8px;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  
  .pdf-button:hover {
    transform: scale(1.05);
  }
  
  .no-pdf {
    color: #888;
    font-style: italic;
    font-weight: 400;
    display: block;
    text-align: center;
  }

  .prev-button,
  .next-button {
    padding: 8px 12px;
    background-color: #006bb3;
    color: white;
    border: none;
    border-radius: 5px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background 0.3s ease-in-out, transform 0.2s ease-in-out;
  }
  