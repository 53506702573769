/* General Styles */
.role-list-container {
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

/* Header */
.role-list-container .header {
    color: #333;
    text-align: center;
    margin-bottom: 20px;
}

/* Search Bar */
.search-bar {
    display: flex;
    justify-content: space-between;
    align-items: center;
  
}

.search-input {
    padding: 10px;
    width: 200px;
    border-radius: 20px;
    border: 1px solid #ccc;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

/* Create Role Button */
.create-button {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
}

.create-button:hover {
    background-color: #45a049;
}

/* Table Styles */
.role-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    overflow: hidden;
}

.role-table thead {
    background-color: #001861;
}

.role-table th {
    padding: 12px 15px;
    font-weight: 600;
    color: white;
    text-transform: uppercase;
    text-align: left;
    border: 1px solid #ddd;
}

.role-table td {
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
    border: 1px solid #ddd;
}

/* Row Styles */
.role-table tr:nth-child(even) {
    background-color: #f9f9f9;
}


/* Action Buttons */
.action-buttons {
    display: flex;
    gap: 10px;
}

.edit-btn,
.delete-btn {
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
}

.edit-btn {
    background-color: #008cba;
}

.edit-btn:hover {
    background-color: #007bb5;
}

.delete-btn {
    background-color: #f44336;
}

.delete-btn:hover {
    background-color: #e53935;
}

/* Add this to your RoleList.css */

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 400px;
    text-align: center;
}

.modal-actions {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
}

.confirm-btn,
.cancel-btn {
    padding: 10px 20px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

.confirm-btn {
    background-color: #4CAF50;
    color: white;
}

.cancel-btn {
    background-color: #f44336;
    color: white;
}

/* Add this to your RoleList.css */

/* Style for the close button (X) */
.close-btn {
    position: absolute;
    top: 10px;
    right: 10px;
    background: none;
    border: none;
    font-size: 24px;
    color: #333;
    cursor: pointer;
    transition: color 0.3s;
}

.close-btn:hover {
    color: #f44336;
    background-color: white;
}

.role-table th:first-child,
.role-table td:first-child {
    width: 70%;
}

.role-table th:last-child,
.role-table td:last-child {
    width: 100%;
}

.pagination {
    display: flex;
    justify-content: end;
    gap: 8px;
    margin: 20px 0;
  
  }
  
  .prev-button,
  .next-button {
    background-color: #003b72;
    color: white;
    border: none;
    padding: 8px 16px;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    margin-bottom: 15px;
  }
  
  .prev-button:disabled,
  .next-button:disabled {
    background-color: #c0c0c0;
    cursor: not-allowed;
  }
  
  .page-number {
    background-color: #f0f0f0;
    color: #333;
    border: none;
    padding: 8px 12px;
    font-size: 11px;
    cursor: pointer;
    border-radius: 4px;
    transition: background-color 0.3s;
    height: 30px;   
  }
  
  .next-button {
    margin-right: 3px;
  }
  
  .page-number.active {
    background-color: #007bff;
    color: white;
  }
  
  .page-number:focus {
    outline: none;
  }
.next-btn{
    background-color: #007bff;
    color: white;
}