.new-slider-container {
  background:#f4f6f9;
  border-radius: 16px;
  box-shadow: 0 6px 15px rgba(0, 0, 0, 0.15);
  margin: 30px auto;
  max-width: 1000px;
  padding: 30px;
  width: calc(100% - 40px);
  box-sizing: border-box;
}

.new-slider-container h2 {
  font-size: 24px;
    margin-bottom: 20px;
    color: #001861;
    text-align: center;
}

.new-slider-container form {
  margin-bottom: 20px;
}

.new-slider-container label {
  display: block;
  margin-bottom: 8px;
  font-size: 1rem;
  color: #34495e;
  font-weight: 500;
}

.new-slider-container input {
  width: 100%;
  padding: 12px;
  font-size: 1rem;
  border-radius: 8px;
  border: 1px solid #dcdfe3;
  transition: all 0.3s ease;
  background: #f8f9fa;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.05);
}

.new-slider-container input:focus {
  border-color: #3498db;
  outline: none;
  background: #ffffff;
  box-shadow: 0 0 8px rgba(52, 152, 219, 0.3);
}

.new-slider-container button {
  display: block;
  margin: 20px auto;
  background-color: #3498db;
  color: white;
  padding: 12px 30px;
  font-size: 1.1rem;
  font-weight: bold;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 3px 8px rgba(52, 152, 219, 0.3);
}

.new-slider-container button:hover {
  background-color: #2980b9;
  box-shadow: 0 6px 12px rgba(41, 128, 185, 0.4);
}

.new-slider-container button:active {
  background-color: #1f618d;
  box-shadow: 0 2px 5px rgba(31, 97, 141, 0.5);
}

.new-slider-container button:focus {
  outline: none;
}

.new-slider-container form div {
  margin-bottom: 20px;
}

.new-slider-container form div:last-child {
  margin-bottom: 0;
}
