/* General Styles */
.form-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f4f6f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}
.academy-form {
  display: flex;
  flex-direction: column;
}

.form-group {
  margin-bottom: 20px;
}

.form-label {
  font-weight: bold;
  color: #555;
  margin-bottom: 8px;
  display: block;
}

.form-input,
.form-select,
.form-textarea {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}

.form-textarea {
  resize: vertical;
}

.add-more-container {
  display: flex;
  gap: 10px;
  align-items: center;
  margin-top: 7px;
}

.add-more-btn-mobile {
  background-color: #001861;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 5px;
  width: 70px;
}
.remove-btn-mobile {
  background-color: #4caf50;
  color: white;
  padding: 8px 12px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  transition: background-color 0.3s ease;
  margin-top: 5px;
}

.remove-btn-mobile{
  background-color: #f44336;
}

.add-more-btn-mobile:hover {
  background-color: #45a049;
}

.remove-btn-mobile:hover {
  background-color: #d32f2f;
}

.form-submit-btn {
  background-color: #4caf50;
  color: white;
  padding: 12px 20px;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  transition: background-color 0.3s ease;
}

.form-submit-btn:hover {
  background-color: #45a049;
}

.academy-select__control {
  height: 50px; 
  font-size: 14px;
}

.academy-select__control .react-select__input {
  padding: 5px; 
}
.css-1uccc91-singleValue {
  z-index: 1000 !important; /* Ensure dropdown options are above other elements */
}

.css-1okebmr-menu {
  display: block !important; /* Ensure the dropdown menu is visible */
}