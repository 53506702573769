.bulk-booking-container {
  width: 95%;
  margin: 10px auto;
  padding: 10px;
  background-color: #f4f6f9;
  border-radius: 10px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  font-family: 'Poppins', sans-serif;
}

.bulk-booking-heading {
  font-size: 28px;
  font-weight: bold;
  text-align: center;
  color: #2c3e50;
  margin-bottom: 30px;
}

.bulk-booking-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-field {
  display: flex;
  flex-direction: column;
}

.form-field label {
  font-size: 14px;
  color: #333;
  margin-bottom: 8px;
}

.input-field,
.input-select,
.input-textarea {
  width: 100%;
  padding: 12px;
  font-size: 14px;
  border: 1px solid #ddd;
  border-radius: 6px;
  box-sizing: border-box;
  background-color: #f9f9f9;
  transition: border-color 0.3s ease;
}

.input-field:focus,
.input-select:focus,
.input-textarea:focus {
  outline: none;
  border-color: #007bff;
}

.input-textarea {
  height: 120px;
  resize: vertical;
}

.submit-btn {
  padding: 12px;
  background-color: #001861;
  color: white;
  border: none;
  border-radius: 6px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-btn:hover {
  background-color: #1c6693;
  transform: scale(1.05);
}

.weekdays-checkbox-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-top: 10px;
}

.weekday-checkbox-label {
  display: flex;
  align-items: center;
  gap: 5px;
  cursor: pointer;
}

.weekday-checkbox-label input {
  margin: 0;
  cursor: pointer;
}
 
.sales-filter-court-booking .css-13cymwt-control {

  border-style: none !important;
  min-height: none !important;
}
