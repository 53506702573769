.razorpayauth-container {
    width: 100%;
    max-width: 1100px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f7fc;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h3 {
    text-align: center;
    color: #333;
  }
  
  .razorpayauth-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  .input-group {
    display: flex;
    flex-direction: column;
  }
  
  .input-group label {
    font-size: 14px;
  
    margin-bottom: 6px;
  }
  
  .input-group input,
  .input-group select {
    padding: 10px;
    font-size: 14px;
    border: 1px solid #ddd;
    border-radius: 4px;
    outline: none;
    width: 100%;
    box-sizing: border-box;
  }
  
  .input-group input:focus,
  .input-group select:focus {
    border-color: #66a6ff;
    box-shadow: 0 0 5px rgba(102, 166, 255, 0.5);
  }
  
  .submit-btn {
    padding: 12px 20px;
    background-color: #003b72;
    border: none;
    color: #fff;
    font-size: 16px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  

  