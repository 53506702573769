.navbar {
  background: #001861; 
  color: #ffffff;
}

.navbar-toolbar {
  display: flex;
  justify-content: space-between;
  padding: 0 20px;
  background: linear-gradient(135deg, #003365, #006bb3); 
  border-bottom: 2px solid rgba(255, 255, 255, 0.3);
}

.navbar-logo-container {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.navbar-logo {
  width: 50px;
  height: 35px;
  margin-right: 10px;
}

.navbar-title {
  font-weight: 600;
  font-size: px;
  color: #ffffff;
  text-align: center;
  font-family: 'Poppins', sans-serif;
}

.navbar-icon {
  margin-left: 15px;
}

.navbar-icon:hover {
  color: #001861;
}

.navbar-menu {
  background-color: #001861; 
}
