
  /* Style for the "Solve" button when the enquiry is not resolved */
  .resolve-button {
    background-color: #007bff; /* Blue */
    color: white;
    font-weight: bold;
    border: 1px solid #007bff;
  }
  
  .resolve-button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
  }
  
  .resolve-button:active {
    background-color: #004085;
  }
  
  /* Style for the "Resolved" button when the enquiry is resolved */
  .resolved-button {
    background-color: #28a745; /* Green */
    color: white;
    font-weight: bold;
    border: 1px solid #28a745;
  }
  
  .resolved-button:hover {
    background-color: #218838;
    transform: scale(1.05);
  }
  
  .resolved-button:active {
    background-color: #1e7e34;
  }


  /* //new code */
.filter-container-summer {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  margin-bottom: 20px;
}

.floating-select {
  position: relative;
  flex: 1 1 200px;
}

.floating-select select,
.floating-select input {
  width: 90%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 14px;
}


.payment-list-scroll-container {
  width: 100%;
  overflow-x: auto;
}

.payment-list-table {
  width: 100%;
  border-collapse: collapse;
}

.payment-list-table th,
.payment-list-table td {
  padding: 10px;
  text-align: left;
  font-size: 14px;
}



