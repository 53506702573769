.update-bank-container {
    padding: 20px;
    max-width: 400px;
    margin: 0 auto;
    border: 1px solid #ccc;
    border-radius: 8px;
    background-color: #f9f9f9;
  }
  
  .update-bank-heading {
    text-align: center;
    margin-bottom: 20px;
    font-size: 1.5rem;
    color: #333;
  }
  
  .form-group {
    margin-bottom: 15px;
  }
  
  .form-group label {
    display: block;
    margin-bottom: 5px;
    font-weight: bold;
    color: #555;
  }
  
  .form-input {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
    font-size: 1rem;
    box-sizing: border-box;
  }
  
  .form-input:focus {
    border-color: #66a6ff;
    outline: none;
  }
  
  .bank-button {
    width: 100%;
    padding: 10px;
    border-radius: 4px;
    border: none;
    font-size: 1rem;
    background-color: #66a6ff;
    color: #fff;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .bank-button:hover:not(.disabled) {
    background-color: #0056b3;
  }
  
  .bank-button.disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }
  .file-label {
    display: inline-block;
    background-color: #007bff;
    color: white;
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 5px;
    cursor: pointer;
    transition: background 0.3s ease;
  }
  
  .file-label:hover {
    background-color: #0056b3;
  }
  .cancel, .upload{
    width: 100%;
  }
  .cancel{
    background-color: red;
    color: white;
  }
  .upload{
    background-color: green;
    color: white;
  }