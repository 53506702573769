.settle-payment-container {
  width: 30%;
  height: 100%;
  padding-bottom: 100px;
  background-color: #ffffff;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.1);
  position: fixed;
  top: 0;
  right: 0;
  padding: 20px;
  overflow-y: auto;
  z-index: 1000;
}

  
  /* Title style */
  .settle-payment-title {
    font-size: 30px;
    margin-bottom: 20px;
    color: #003366;
    text-align: center;
  }
  
  /* Form styling */
  .settle-payment-form {
    flex-direction: column;
  }
  
  /* Label styling */
  .input-label {
    font-size: 1rem;
    font-weight: bold;
    margin-bottom: 8px;
    color: #555;
  }
  
  /* General input and select field styling */
  .input-field,
  .select-field,
  .textarea-field {
    padding: 10px;
    font-size: 1rem;
    border: 1px solid white;
    border-radius: 5px;
    outline: none;
    transition: border 0.3s ease;
    width: 100%;
  }
  
  /* Focus effect */
  .input-field:focus,
  .select-field:focus,
  .textarea-field:focus {
    border-color: #007bff;
  }
  
  .textarea-field {
    height: 100px;
    resize: vertical;
  }
  
  .select-field {
    padding: 10px;
    font-size: 1rem;
    border-radius: 5px;
  }

  .submit-button {
    padding: 12px 20px;
    font-size: 1.2rem;
    background-color: #28a745;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  
  }
  
  
  /* Spacing between input fields */
  .input-container {
    margin-bottom: 15px;
  }
