

.profile-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 100vh;
  padding: 0px;
  position: relative;
  overflow: hidden;
  font-family: 'Poppins', sans-serif;

}

/* Sports-themed background animations */
.profile-container::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: url('https://www.transparenttextures.com/patterns/swimming-pool.png'); /* Swimming pool texture */
  opacity: 0.1;
  z-index: -1;
  animation: swim 10s linear infinite;
}

@keyframes swim {
  0% {
    background-position: 0 0;
  }
  100% {
    background-position: 100% 100%;
  }
}

.profile-card {
  background: linear-gradient(135deg, #1e3c72 0%, #2a5298 100%);
  backdrop-filter: blur(10px);
  border-radius: 20px;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  padding: 30px;
  width: 100%;
  animation: fadeIn 1s ease-in-out;
  position: relative;
  overflow: hidden;
}

/* Football emoji animation */
.profile-card::before {
  content: '⚽'; /* Football emoji */
  position: absolute;
  top: -50px;
  right: -50px;
  font-size: 60px;
  opacity: 0.3;
  animation: football 5s linear infinite;
}

@keyframes football {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(-200px, 200px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

/* Badminton shuttlecock emoji animation */
.profile-card::after {
  content: '⚽'; 
  position: absolute;
  bottom: -50px;
  left: -50px;
  font-size: 60px;
  opacity: 0.3;
  animation: badminton 6s linear infinite;
}

@keyframes badminton {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(200px, -200px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

/* Swimming emoji animation */
.profile-card .swimming-emoji {
  position: absolute;
  top: 50%;
  left: -50px;
  font-size: 60px;
  opacity: 0.3;
  animation: swimming 8s linear infinite;
}

@keyframes swimming {
  0% {
    transform: translate(0, 0) rotate(0deg);
  }
  50% {
    transform: translate(400px, -100px) rotate(180deg);
  }
  100% {
    transform: translate(0, 0) rotate(360deg);
  }
}

.profile-header {
  text-align: center;
  margin-bottom: 30px;
}

.profile-title {
  font-size: 2.5rem;
  font-weight: 700;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.profile-info {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.profile-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.profile-row-pre {
  align-items: center;
  padding: 15px;
  background: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}
.profile-row:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.3);
}

.label {
  font-size: 1.1rem;
  font-weight: 600;
  color: #ff7e5f;
}

.value {
  font-size: 1rem;
  font-weight: 500;
  color: #fff;
}

.privileges {
  margin-top: 20px;
}

.privileges ul {
  list-style: none;
  padding: 0;
}

.privileges ul li {
  margin-bottom: 15px;
}

.privileges ul li strong {
  font-size: 1.2rem;
  font-weight: 700;
  color: #feb47b;
  display: block;
  margin-bottom: 10px;
}

.privileges ul li ul {
  list-style: none;
  padding-left: 20px;
}

.privileges ul li ul li {
  font-size: 0.95rem;
  color: #ddd;
  padding: 5px 0;
  border-left: 2px solid #ff7e5f;
  padding-left: 10px;
  transition: all 0.3s ease;
}

.privileges ul li ul li:hover {
  color: #fff;
  border-left: 2px solid #feb47b;
  transform: translateX(10px);
}

.show-more-button {
  margin-top: 20px;
  padding: 10px 20px;
  background: linear-gradient(90deg, #ff7e5f, #feb47b);
  color: #fff;
  border: none;
  border-radius: 25px;
  font-size: 1rem;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  animation: pulse 2s infinite;
}

.show-more-button:hover {
  background: linear-gradient(90deg, #feb47b, #ff7e5f);
  transform: scale(1.05);
  box-shadow: 0 8px 16px rgba(255, 126, 95, 0.4);
}

/* Animations */
@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes glow {
  0% {
    text-shadow: 0 0 5px rgba(255, 126, 95, 0.8), 0 0 10px rgba(255, 126, 95, 0.8);
  }
  100% {
    text-shadow: 0 0 20px rgba(255, 126, 95, 0.8), 0 0 40px rgba(255, 126, 95, 0.8);
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
  100% {
    transform: scale(1);
  }
}