/* General Styles */
.container {
    padding: 20px;
    font-family: 'Poppins', sans-serif;
  }
  
 
  /* Create User Button */
  .create-button {
    padding: 10px 20px;
    border-radius: 20px;
    background-color: #4caf50;
    color: #fff;
    border: none;
    cursor: pointer;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
    transition: background-color 0.3s;
  }
  
  .create-button:hover {
    background-color: #45a049;
  }
  

 .user-table,
.academy-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 10px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.user-table th,
.academy-table th {
    border: 1px solid #ddd;
    padding: 12px 15px;
    background-color: #00356B;
    color: white;
    font-weight: 600;
    text-transform: uppercase;
}

.user-table td,
.academy-table td {
    border: 1px solid #ddd;
    padding: 12px 15px;
    font-size: 14px;
    vertical-align: middle;
}

.user-table tr:nth-child(even),
.academy-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

.user-table tr:hover,
.academy-table tr:hover {
    background-color: #f1f1f1;
    transition: background-color 0.3s ease;
}

  
  /* Action Buttons */
  .action-buttons {
    display: flex;
    gap: 10px;
  }
  
  .edit-button,
  .delete-button,.reset-password-button {
    padding: 5px 10px;
    border-radius: 10px;
    color: #fff;
    border: none;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .edit-button,.reset-password-button {
    background-color: #008cba;
  }
  
  .edit-button:hover ,.reset-password-button{
    background-color: #007bb5;
  }
  
  .delete-button{
    background-color: #f44336;
  }
  
  .delete-button:hover{
    background-color: #e53935;
  }
 
  /* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
  }
  
  .cancel-button {
    padding: 10px 20px;
    background-color: #f44336;
    color: #fff;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }
  /* Modal Styles */
.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.6);
    z-index: 1000;
    animation: fadeIn 0.3s ease-in-out;
  }
  
  /* Modal Content */
  .modal-content {
    width: 400px;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.3s ease;
  }

  .modal-content-delete {
    width: 350px;
    padding: 20px;
    background: #ffffff;
    border-radius: 12px;
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
    position: relative;
    animation: slideIn 0.3s ease;
  }
  
  /* Close Button */
  .close-button {
    position: absolute;
    top: 10px;
    right: 15px;
    font-size: 20px;
    color: #333;
    background: none;
    border: none;
    cursor: pointer;
    font-weight: bold;
  }
  
  .close-button:hover {
    color: #f44336;
    background-color: white;
  }
  
  /* Input Styles */
  .modal-content input {
    width: 95%;
    padding: 10px;
    margin-bottom: 15px;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
  }
  
  /* Save Button */
  .save-button {
    width: 100%;
    padding: 12px;
    background-color: #4caf50;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .save-button:hover {
    background-color: #45a049;
  }
  
  /* Delete Confirmation Button */
  .delete-confirm-button {
    width: 100%;
    background-color: #45a049;
    color: #ffffff;
    border: none;
    border-radius: 8px;
  
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  .delete-confirm-button:hover {
    background-color: #45a049;
  }
  
  /* Cancel Button */
  .cancel-button {
    width: 100%;
    padding: 12px;
    background-color: #9e9e9e;
    color: #ffffff;
    border: none;
    border-radius: 8px;
    font-size: 16px;
    cursor: pointer;
    margin-top: 10px;
    transition: background-color 0.3s ease;
  }
  
  .cancel-button:hover {
    background-color: #757575;
  }
  
  /* Animations */
  @keyframes fadeIn {
    from {
      opacity: 0;
    }
    to {
      opacity: 1;
    }
  }
  
  @keyframes slideIn {
    from {
      transform: translateY(-20px);
    }
    to {
      transform: translateY(0);
    }
  }
  .delete-confirm-button,
  .cancel-button {
    width: 150px; /* Set a fixed width */
    height: 45px; /* Set a fixed height */
    padding: 10px 0; /* Adjust padding for better alignment */
    font-size: 16px; /* Uniform font size */
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background-color 0.3s ease;
  }
  
  
  /* Cancel Button Styles */
  .cancel-button {
    background-color: #bdc3c7;
    color: black;
  }
  
  .cancel-button:hover {
    background-color: #757575;
  }
  .user-list-container{
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  .dropdown-user {
    position: absolute; 
    background-color: #fff; 
    border: 1px solid #ccc; 
    border-radius: 5px; 
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); 
    padding: 10px;
    z-index: 1000; 
    display: flex;
    flex-direction: column;
    gap: 7px;
  }
  .next-button{
    background-color: #007bff;
    color: white;
  }