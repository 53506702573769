/* Container for the form */
.new-plan-form-container {
    padding: 20px;
    max-width: 1100px;
    margin: auto;
    background-color: #f4f6f9;
    border-radius: 8px;
  }
  
  /* Style for the form */
  .new-plan-form {
    display: flex;
    flex-direction: column;
    gap: 20px;
  }
  
  /* General form group styling */
  .form-group {
    display: flex;
    flex-direction: column;
  }
  
  /* Style for all text inputs */
  .input-text,
  .input-select {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
  }
  
  /* Style for file input */
  .input-file {
    padding: 10px;
    font-size: 16px;
    border: 1px solid #ccc;
    border-radius: 4px;
    background-color: #f7f7f7;
  }
  
  /* Submit button styling */
  .submit-button {
    background-color: #4CAF50;
    color: white;
    padding: 10px 20px;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: 'center', 
  }
  
  .submit-button:hover {
    background-color: #45a049;
  }
  
 .create-plan{
    font-weight: bold;  /* Makes the text bold */
    text-align: center; /* Centers the text */
    font-size: 24px;    /* Optional: Adjust font size for better visibility */
    margin-bottom: 20px; /* Optional: Adds space below the heading */
  }
  .choose-button {
    display: flex;
    align-items: center;
    gap: 10px;
  }
  
  .choose-button button {
    background-color: #006bb3;;
    color: white;
    border: none;
    padding: 8px 12px;
    border-radius: 5px;
    cursor: pointer;
    font-size: 14px;
  }

  