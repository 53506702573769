.form-container {
    max-width: 1200px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f4f6f9;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #001861;
    text-align: center;
  }
  .header{
    font-size: 24px;
    margin-bottom: 20px;
    color: #001861;
    text-align: center;
  }
  .academy-form {
    display: flex;
    flex-direction: column;
  }
  
  .form-group {
    margin-bottom: 20px;
  }
  
  .form-label {
    font-weight: bold;
    color: #555;
  }
  
  .form-input,
  .form-select,
  .form-textarea,
  .form-file {
    width: 100%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 14px;
  }
  
  .form-textarea {
    resize: vertical;
  }
  
  .form-checkbox {
    margin: 0 !important;
    width: auto !important;
    height: auto !important; 
    align-self: flex-start !important; 
  }
  
  .form-submit-btn {
    background-color: #4caf50;
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    width: 100%;
    transition: background-color 0.3s ease;
  }
  
  .form-submit-btn:hover {
    background-color: #45a049;
  }
  /* Example in your styles.css or App.css */
.breadcrumb {
  margin: 20px 0; /* This will add margin to the top and bottom */
}

.breadcrumb-link {
  text-decoration: none;
  color: #007bff; /* Change the color of the link as needed */
}

.breadcrumb-link:hover {
  text-decoration: underline; /* Optional: underline on hover */
}
.image-preview {
  margin-top: 10px;
}

.cover-image-preview {
  max-width: 100%;
  max-height: 200px;
  object-fit: cover;
}
