/* Container */
.form-container {
    width: 100%;
    margin: 0 auto;
    padding: 20px;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    background-color: #f9f9f9;
  }
  
  /* Heading */
  .form-container h2 {
    text-align: center;
    margin-bottom: 20px;
    color: #333;
  }
  
  /* Form Group */
  .form-group {
    margin-bottom: 20px;
  }
  
  /* Labels */
  .form-group label {
    display: block;
    margin-bottom: 8px;
    font-weight: 600;
    color: #555;
  }
  
  /* Inputs */
  .form-group input,
  .form-group select {
    padding: 12px;
    width: 100%;
    border: 1px solid #ddd;
    border-radius: 8px;
    font-size: 14px;
    color: #333;
  }
  
  /* Input Focus State */
  .form-group input:focus,
  .form-group select:focus {
    border-color: #66a6ff;
    outline: none;
    box-shadow: 0 0 5px rgba(102, 166, 255, 0.5);
  }
  
  /* Dropdown Container */
  .role-dropdown {
    position: relative;
  }
  
  /* Role Dropdown Display */
  .role-display {
    padding: 12px;
    border: 1px solid #ddd;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  
  /* Dropdown Open State */
  .role-options {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    background-color: #fff;
    border: 1px solid #ddd;
    border-radius: 8px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    margin-top: 10px;
  }
  
  /* Role Option Item */
  .role-option {
    padding: 10px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: background-color 0.2s;
  }
  
  .role-option:hover {
    background-color: #f0f8ff;
  }
  
  /* Selected Role Icon */
  .role-option span {
    margin-right: 10px;
    color: green;
  }
  
  /* Button */
  .submit-button {
    padding: 12px 20px;
    width: 100%;
    background-color: #001861;
    border: none;
    border-radius: 8px;
    color: #fff;
    font-weight: bold;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.3s;
  }
  
  .submit-button:hover {
    background-color: #5595e9;
  }
  .error-message {
    color: #b22222;
    background-color: #ffe5e5;
    border: 1px solid #b22222;
    border-radius: 4px;
    padding: 10px;
    margin-bottom: 15px;
    font-size: 14px;
  }
  