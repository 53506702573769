.sidebar {
  width: 210px;
  min-height: 100vh;
  background: #003366;
  color: #fff;
  padding-left: 7px;
  padding-top: 7px;
  padding-bottom: 7px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-shadow: 4px 0px 15px rgba(0, 0, 0, 0.3);
  border-right: 2px solid #003b72;
}

.sidebar nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

.sidebar nav ul li {
  margin: 0px;
  border-bottom: 1px solid #003b72;
}

.sidebar nav ul li a {
  color: #fff;
  text-decoration: none;
  font-size: 14px;
  display: block;
  
  padding: 12px;
  padding-left: 10px;
  border-radius: 5px;
  border: 1px solid transparent;
}

.sidebar nav ul li .active {
  background-color: #fff;
  color: #001861;
  font-weight: bold;
}

.sidebar nav ul li select {
  background: #003b72;
  color: #fff;
  border: 1px solid #003b72;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #003b72;
}

.sidebar nav ul li select:hover {
  background: #fff;
  /* Highlight background on hover */
  color: #001861;
  border-color: #000;
  /* Change to black for better visibility */
}

.sidebar nav ul li ul {
  list-style: none;
}

.sidebar nav ul li ul li {
  margin-top: 5px;
}

.sidebar nav ul li ul li a {
  font-size: 14px;
  padding: 8px 10px;
  border: 1px solid transparent;
}

.sidebar .sidebar-footer {
  margin-top: 30px;
  font-size: 14px;
  text-align: center;
  color: #fff;
  opacity: 0.7;
  border-top: 1px solid #003b72;
  padding-top: 10px;
}


.subsection li {
  list-style: none;
  margin: 5px 0;
}

.subsection a {
  font-size: 12px;
  padding: 5px;
  border-bottom: 0.5px solid #003b72;
  color: #003b72;
  /* Link color */
  text-decoration: none;
}


.subsection a.active {
  color: #001861;
  font-size: 14px;
  display: block;
  padding: 8px 10px;
  border-radius: 5px;
}

.subsection a:hover {
  background-color: #f0f8ff;
  color: darkblue;
}

.subsection a,
.subsection a:visited {
  color: blue;
}


.sidebar nav ul li .subsection li {
  border: none;
  padding: 0px;
  margin: 0px;

}

.subsection a:link {
  color: blue;
}

.subsection a:visited {
  color: blue;
}

.sidebar-toggle {
  padding: 10px;
  text-align: center;
  background-color: #003b72;
  cursor: pointer;
  color: #ecf0f1;
  font-size: 1.5rem;
}

.sidebar.collapsed {
  width: 55px;

}

.menu-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
  color: transparent;
  /* Text color */
  border-radius: 5px;
}

.menu-item:hover {
  background-color: #005f99;
  /* Hover effect with darker background */
}

.menu-item:hover .subsection {
  display: block;
  opacity: 1;
  visibility: visible;
  /* Show the subsection on hover */
}

.menu-item:hover>a {
  color: aliceblue;
  /* Change color of the link when hovered */
}

.sidebar.collapsed .subsection {
  width: 200px;

}

.sidebar.collapsed .subsection a {
  font-size: 14px;
  /* Increase font size for better readability */
  padding: 8px;
}

.subsection {
  display: none;
  /* Hidden by default */
  position: absolute;
  left: 100%;
  /* Position to the right of the menu item */
  top: 3%;
  background-color: #ffffff;
  /* White background */
  width: 220px;
  padding: 5px 10px;
  /* Reduced padding */
  border-radius: 3px;
  box-shadow: 2px 2px 10px rgba(0, 0, 0, 0.1);
  /* Soft shadow */
  opacity: 0;
  visibility: hidden;
  z-index: 10;
  max-height: 400px;
  /* Limit the height */
  overflow-y: auto;
  /* Enable vertical scrolling */
  border: 5px solid transparent;
  /* Transparent border */
}

.subsection::-webkit-scrollbar {
  width: 4px;
  /* Set the width of the scrollbar */
}

.subsection::-webkit-scrollbar-thumb {
  background-color: #003b72;
  /* Color of the scrollbar thumb */
  border-radius: 4px;
  /* Rounded corners */
}

.subsection::-webkit-scrollbar-track {
  background: #f1f1f1;
  /* Background of the scrollbar track */
}

.sidebar.collapsed .subsection {
  width: 100px;
  max-height: 400px;
  /* Further reduce the height in collapsed state */
}

.subsection a {
  font-size: 12px;
  padding: 5px;
  border-bottom: 0.5px solid #003b72;
  color: #003b72;
  /* Link color */
  text-decoration: none;
  /* Remove underline from links */

}

/* Make the link a flex container */
.menu-link {
  display: flex;
  align-items: center;
  /* Vertically center the items */
  justify-content: center;
  /* Horizontally center the items (for when collapsed) */
  padding: 10px;
  /* Optional: Adjust padding for spacing */
  text-decoration: none;
  /* Remove underline */
}

.icon {
  font-size: 1.5rem;
  /* Adjust icon size */
  display: inline-flex;
  /* Make icon an inline flexbox to control position */
  align-items: center;
  /* Center the icon vertically */
  justify-content: center;
  /* Center the icon horizontally */
}

.text {
  margin-left: 10px;
  /* Space between icon and text */
}

.text.hidden {
  display: none;
  /* Hide the text when collapsed */
}

@media (max-width: 768px) {
  .sidebar {
    width: 55px; /* Collapse sidebar width */
  }
  .text {
    display: none;
  }

  /* Reduce padding in collapsed state */
  .sidebar .menu-item {
    padding: 5px 0;
  }

 
.sidebar nav ul li select {
  background: #003b72;
  color: #fff;
  border: 1px solid #003b72;
  font-size: 14px;
  padding: 10px;
  border-radius: 5px;
  width: 100%;
  margin-top: 10px;
  border-bottom: 1px solid #003b72;
}

.sidebar nav ul li select:hover {
  background: #fff;
  /* Highlight background on hover */
  color: #001861;
  border-color: #000;
  /* Change to black for better visibility */
}

  /* Make submenu hidden by default in collapsed state */
  .sidebar.collapsed .subsection {
    display: none;
  }

  .sidebar .sidebar-toggle {
    display: block;
    text-align: center;
    background-color: #003b72;
    cursor: pointer;
    color: #ecf0f1;
    font-size: 1.5rem;
  }

  /* If you want to show the sidebar toggle button only on small screens */
  .sidebar.collapsed .sidebar-toggle {
    display: block;
  }
}

/* Show the subsection menu when hovering (for desktop/tablet view) */
.menu-item:hover .subsection {
  display: block;
  opacity: 1;
  visibility: visible;
}