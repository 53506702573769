
.landing-page-wrapper {
  max-width: 1200px;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: #f4f6f9;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
  margin: 20px auto;
}

/* Dropdown Styles */
.landing-page-container {
  margin-bottom: 30px;
}

.dropdown-label {
  font-size: 18px;
  color: #555;
  margin-bottom: 10px;
  display: block;
}

.landing-page-select {
  width: 100%;
  max-width: 300px;
  padding: 12px 20px;
  font-size: 16px;
  border: 2px solid #ddd;
  border-radius: 8px;
  background-color: #fff;
  color: #555;
  transition: border-color 0.3s ease;
}

.landing-page-select:focus {
  border-color: #5c6bc0;
  outline: none;
}

/* Image Customization Container */
.image-customization-container {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));
  gap: 30px;
}

.image-card {
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  padding: 25px;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
}

.image-card:hover {
  transform: translateY(-8px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.image-title {
  font-size: 20px;
  color: #333;
  margin-bottom: 15px;
  font-weight: 600;
}

/* Image Display */
.image-display {
  width: 100%;
  height: auto;
  border-radius: 10px;
  max-height: 300px;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.image-display:hover {
  transform: scale(1.05);
}

/* Buttons */
.update-image-btn {
  padding: 12px 25px;
  font-size: 16px;
  font-weight: 600;
  background: linear-gradient(90deg, #5c6bc0, #3a4c9b);
  color: #fff;
  border: none;
  border-radius: 25px;
  cursor: pointer;
  transition: transform 0.3s ease, background 0.3s ease;
  margin-top: 15px;
}

.update-image-btn:hover {
  transform: translateY(-5px);
  background: linear-gradient(90deg, #3a4c9b, #5c6bc0);
}

.update-image-btn:focus {
  outline: none;
  box-shadow: 0 0 10px rgba(92, 107, 192, 0.5);
}

/* Hidden file input */
input[type="file"] {
  display: none;
}
.alert-success {
  background-color: #28a745;
  color: #fff;
  margin: 20px 0;
  border-radius: 8px;
  font-size: 16px;
  font-weight: bold;
  display: flex;
  justify-content: space-between;
  align-items: center;
  animation: fadeIn 0.5s ease-out;
  position: relative;
}

.alert-message {
  flex-grow: 1;
  padding-right: 20px; /* Space for the close button */
}

.alert-close-btn {
  background: none;
  border: none;
  color: #fff;
  font-size: 24px;
  cursor: pointer;
  padding: 0 10px;
  font-weight: bold;
  transition: opacity 0.3s ease;
}

.alert-close-btn:hover {
  opacity: 0.7;
}

/* Fade-In Animation */
@keyframes fadeIn {
  0% { opacity: 0; transform: translateY(-20px); }
  100% { opacity: 1; transform: translateY(0); }
}

/* Close Button Hover */
.alert-close-btn:hover {
  opacity: 0.7;
}

/* Fade-Out Animation */
@keyframes fadeOut {
  0% { opacity: 1; transform: translateY(0); }
  100% { opacity: 0; transform: translateY(-20px); }
}