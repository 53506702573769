/* General container styles */
.challenges-container {
    background: #f4f6f9;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    margin: 20px auto;
    max-width: 1200px;
    width: 100%;
    box-sizing: border-box;
    padding: 10px;
}

.start-date-filter {
    padding: 5px;
    text-align: center;
    border-radius: 8px;
    margin-right: 250px;
}

/* Header styles */
.header {
    text-align: center;
    font-size: 28px;
    font-weight: bold;
    margin-bottom: 20px;
    color: #333;
}

/* Top row for search/input styles */
.top-row {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
}

.search-input {
    padding: 10px;
    font-size: 16px;
    border-radius: 5px;
    border: 1px solid #ccc;
    width: 200px;

    box-sizing: border-box;
}

/* Table styles */
.challenges-table {
    width: 100%;
    border-collapse: collapse;
    margin-top: 20px;
}

.challenges-table th,
.challenges-table td {
    padding: 12px;
    text-align: center;
    border: 1px solid #ddd;
}

.challenges-table th {
    background-color: #003366;
    color: white;
    text-transform: uppercase;
    font-weight: bold;
}

.challenges-table tr:nth-child(even) {
    background-color: #f9f9f9;
}

/* Button styles */
.edit-button,
.delete-button {
    padding: 5px 12px;
    margin: 5px;
    cursor: pointer;
    border-radius: 5px;
    font-size: 14px;
}

.edit-button {
    background-color: #4CAF50;
    color: white;
    border: none;
}

.edit-button:hover {
    background-color: #45a049;
}

.delete-button {
    background-color: #f44336;
    color: white;
    border: none;
}

.delete-button:hover {
    background-color: #e53935;
}

/* Modal overlay styles */
.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

/* Modal content box */
.modal-content {
    background-color: white;
    padding: 20px;
    border-radius: 10px;
    width: 450px;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.modal-body input {
    width: 100%;
    padding: 10px;
    margin: 10px 0;
    border: 1px solid #ccc;
    border-radius: 5px;
    font-size: 14px;
}

/* Modal footer and button styles */
.modal-footer {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
}

.save-button,
.cancel-button,
.confirm-button {
    padding: 12px 20px;
    cursor: pointer;
    border: none;
    border-radius: 5px;
    font-size: 16px;
}

.save-button {
    background-color: #4CAF50;
    color: white;
}

.save-button:hover {
    background-color: #45a049;
}

.cancel-button,
.confirm-button {
    background-color: #f44336;
    color: white;
}

.cancel-button:hover,
.confirm-button:hover {
    background-color: #e53935;
}

/* Create Challenge Button */
.create-challenge-button {
    padding: 12px 20px;
    background-color: #4CAF50;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    font-size: 16px;
}

.create-challenge-button:hover {
    background-color: #45a049;
}

.filters {
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
    /* Space between input fields */
    justify-content: space-between;
    /* Distribute inputs evenly */
    align-items: center;
    /* Align inputs vertically */
    margin: 20px 0;
    /* Add some vertical spacing */
}

.filter-input {
    flex: 1;
    /* Ensure all inputs are equal in width */
    min-width: 200px;
    /* Minimum width for small screens */
    max-width: 300px;
    /* Maximum width for larger screens */
    padding: 10px 15px;
    /* Add padding for a comfortable input area */
    border: 1px solid #ccc;
    /* Light gray border */
    border-radius: 8px;
    /* Rounded corners */
    font-size: 16px;
    /* Adjust text size for readability */
    outline: none;
    /* Remove default focus outline */
    transition: border-color 0.3s, box-shadow 0.3s;
    /* Smooth transition for hover/focus effects */
}

.filter-input:focus {
    border-color: #66a6ff;
    /* Highlight border on focus */
    box-shadow: 0 0 5px rgba(102, 166, 255, 0.5);
    /* Add glow effect */
}

.filter-input::placeholder {
    color: #aaa;
    /* Light gray placeholder text */
    font-style: italic;
    /* Slightly styled placeholder */
}

.sales-filters {
    display: flex;
    flex-wrap: wrap;
    gap: 1px;
    justify-content: space-between;
    align-items: center;
    height: 100px;
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}

.sales-filters-register {
    display: flex;
    flex-wrap: wrap;
    gap: 90px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}
.sales-filters-payment{
    display: flex;
    flex-wrap: wrap;
    gap: 40px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}
.sales-filters-dashboard{
    display: flex;
    flex-wrap: wrap;
    gap: 0px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}
.sales-filters-leadManagement{
    display: flex;
    gap: 36px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}
.sales-filters-login{
    display: -webkit-box;
    gap: 36px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}
.sales-filters-court{
    display: flex;
    flex-wrap: wrap;
    gap: 100px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}
.sales-filters-subscription {
    display: flex;
    flex-wrap: wrap;
    gap: 300px;
    /* Reduced gap to a reasonable value */
    justify-content: flex-start;
    /* Align items to the left */
    align-items: flex-start;
    /* Align items to the top (remove unnecessary vertical space) */
    height: auto;
    /* Let the height adjust based on content */
    background-color: #ffffff;
    padding: 10px;
    border-radius: 10px;
}

.sales-filter-academy,
.sales-filter-sport,
.sales-filter-plan,
.sales-filter-from-date,
.sales-filter-to-date,
.sales-filter-type,
.sales-filter-status {
    width: 15%;
    padding: 10px;
    border: 2px solid #d1d1d1;
    border-radius: 8px;
    font-size: 14px;
    font-family: 'Poppins', sans-serif;
    background-color: #ffffff;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
    transition: all 0.2s ease-in-out;
}

.space {
    padding: 10px;
}

.sales-filter-academy {
    width: 100%;
    padding: 12px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    appearance: none;
    background-color: #fff;
    color: #000;
}
.sales-filter-court-booking {
    width: 100%;
    padding: 3px;
    border: 1px solid #ccc;
    border-radius: 4px;
    font-size: 16px;
    outline: none;
    appearance: none;
    background-color: #fff;
    color: #000;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.1);
}
.sales-filter-court-booking:focus+label,
.sales-filter-court-booking.has-value+label {
    top: 0;
    font-size: 12px;
    color: black;
    background-color: white;
    padding: 0 5px;
    transition: all 0.3s ease;
}

.sales-filter-academy:focus {
    border-color: black;
}
/* Positioning the label */
.floating-select label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 16px;
    color: black;
    pointer-events: none;
    transition: all 0.3s ease;
}

.sales-filter-academy:focus+label,
.sales-filter-academy.has-value+label {
    top: 0;
    font-size: 12px;
    color: black;
    background-color: white;
    padding: 0 5px;
    transition: all 0.3s ease;
}

.sales-filter-academy:focus {
    border-color: black;
}

.floating-select {
    position: relative;
    width: 13%;
    margin: 20px 0;

}

.input-container {
    position: relative;
    width: 100%;
    margin: 10px 0;
}


.floating-select-logindate {
    position: relative;
    width: 13%;
    margin-right: 350px;

}
.floating-select-logindate label {
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translateY(-50%);
    font-size: 16px;
    color: black;
    pointer-events: none;
    transition: all 0.3s ease;
}

.floating-label {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    color: black;
    pointer-events: none;
    transition: all 0.2s ease-in-out;
    font-size: 16px;
}

.search-input:focus+.floating-label,
.search-input.has-value+.floating-label {
    top: 0;
    left: 10px;
    font-size: 12px;
    color: black;
    background-color: white;
    text-align: center;
}

.custom-dropdown {

    display: inline-block;
}

.dropdown-header {
    padding: 10px;
    border: 1px solid #ccc;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.dropdown-options {
    display: none;
    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    background-color: #fff;
    border: 1px solid #ccc;
    max-height: 200px;
    overflow-y: auto;
    list-style-type: none;
    padding: 0;
    margin: 0;
}

.custom-dropdown:hover .dropdown-options {
    display: block;
}

.dropdown-options li {
    padding: 8px 10px;
    cursor: pointer;
}

.dropdown-options li:hover {
    background-color: #f0f0f0;
}

.dropdown-icon {
    font-size: 14px;
}

.items-per-page-select {
    padding: 10px 15px;
    font-size: 16px;
    border-radius: 6px;
    /* Slightly more rounded corners */
    border: 1px solid #ccc;
    /* Subtle border */
    background-color: #ffffff;
    /* White background */
    color: #333;
    /* Text color */
    width: 200px;
    /* Slightly larger width */
    cursor: pointer;
    /* Pointer cursor */
    appearance: none;
    /* Remove default dropdown arrow */
    -webkit-appearance: none;
    /* Remove default arrow on webkit-based browsers */
    -moz-appearance: none;
    /* Remove default arrow on Firefox */
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    /* Light shadow for a subtle 3D effect */
    transition: all 0.3s ease;
    /* Smooth transition on interaction */
}

/* Custom arrow using pseudo-element */
.items-per-page-select::after {
    content: '▼';
    /* Custom arrow symbol */
    font-size: 14px;
    /* Size of the arrow */
    color: #777;
    /* Arrow color */
    position: absolute;
    right: 15px;
    /* Position the arrow to the right */
    top: 50%;
    transform: translateY(-50%);
    /* Center the arrow vertically */
    pointer-events: none;
    /* Prevent arrow from blocking the click area */
}

/* Style when the select is focused (active state) */
.items-per-page-select:focus {
    outline: none;
    /* Remove default outline */
    border-color: #4CAF50;
    /* Green border on focus */
    background-color: #f1f1f1;
    /* Light background color when focused */
}

/* Hover effect for the select element */
.items-per-page-select:hover {
    border-color: #007bff;
    /* Border color change on hover */
    background-color: #f7f7f7;
    /* Slight background color change */
}

/* Styling the options within the dropdown */
.items-per-page-select option {
    padding: 10px;
    /* Option padding */
    font-size: 16px;
    /* Font size for options */
    color: #333;
    /* Option text color */
    background-color: #fff;
    /* Background color */
    border-bottom: 1px solid #eee;
    /* Light border between options */
}

/* Hover effect for the options */
.items-per-page-select option:hover {
    background-color: #f0f0f0;
    /* Slightly darker background on hover */
}

/* Active option state (when selected) */
.items-per-page-select option:checked {
    background-color: #4CAF50;
    /* Green background for selected option */
    color: white;
    /* White text for selected option */
}
@media (max-width: 768px) {
    .input-container {
        position: relative;
        width: 50%;
        margin: 10px 0;
    }
    .search-input {
        padding: 10px;
        font-size: 16px;
        border-radius: 5px;
        border: 1px solid #ccc;
        width: 100px;
    
        box-sizing: border-box;
    }
}