.courtlist-container {
  background: #f4f6f9;
  border-radius: 12px;
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
  margin: 20px auto;
  max-width: 1200px;
  width: 100%;
  box-sizing: border-box;
  padding: 10px;
}

.courtlist-header {
  font-size: 24px;
  margin-bottom: 20px;
  color: #001861;
  text-align: center;
}

.courtlist-table {
  width: 100%;
  border-collapse: collapse;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}

.courtlist-header-row {
  background-color: #001861;
}

.courtlist-column {
  padding: 12px 15px;
  font-weight: bold;
  color: white;
  text-align: left;
  text-transform: uppercase;
}

.courtlist-cell {
  padding: 12px 15px;
  font-size: 14px;
  vertical-align: middle;
  border: 1px solid #ddd;
}

.courtlist-row:nth-child(even) {
  background-color: #f9f9f9;
}

.courtlist-row:hover {
  background-color: #f1f1f1;
}

.courtlist-dots-btn {
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  padding: 5px;
}

.courtlist-dropdown-item {
  display: block;
  width: 100%;
  padding: 8px 10px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
}

.courtlist-dropdown-item:hover {
  background-color: #f0f0f0;
}

.courtlist-dots-btn.active {
  display: block;
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
}

.modal-content {
  background-color: white;
  padding: 20px;
  border-radius: 10px;
  max-width: 500px;
  width: 100%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 24px;
  cursor: pointer;
}

.modal-actions {
  display: flex;
  justify-content: space-between;
  margin-top: 20px;
}

.confirm-btn,
.cancel-btn {
  padding: 10px 20px;
  font-size: 14px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.confirm-btn {
  background-color: #007bff;
  color: white;
}

.cancel-btn {
  background-color: #ddd;
  color: black;
}

.courtlist-checkbox {
  margin-top: 10px;
  font-size: 14px;
}

.courtlist-dropdown-menu {
  display: none;
  position: absolute;
  background-color: white;
  border: 1px solid #ddd;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.1);
  border-radius: 5px;
  max-width: 100px;
  z-index: 1000;
  right: 10px;
}

.courtlist-dropdown-menu.visible {
  display: block;
}

@media (max-width: 768px) {

  .courtlist-container {
    margin: 10px;
    padding: 8px;
    max-width: 100%;
    width: 100%;
  }

  .courtlist-table {
    width: 100%;
    border-collapse: collapse;
    box-shadow: none;
    border-radius: 0;
  }

  .courtlist-header-row {
    background-color: #001861;
  }

  .courtlist-column {
    padding: 8px;
    font-weight: bold;
    color: white;
    text-align: left;
    text-transform: uppercase;
    font-size: 12px;
  }

  .courtlist-cell {
    padding: 8px;
    font-size: 12px;
    border: 1px solid #ddd;
  }

  .courtlist-row:nth-child(even) {
    background-color: #f9f9f9;
  }

  .courtlist-row:hover {
    background-color: #f1f1f1;
  }

  .courtlist-dots-btn {
    font-size: 18px;
    padding: 5px;
  }

  .courtlist-dropdown-item {
    font-size: 12px;
    padding: 6px;
  }

  .courtlist-dropdown-item:hover {
    background-color: #f0f0f0;
  }

 
  .modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
  }

  .modal-content {
    width: 90%;
    max-width: 400px;
    padding: 15px;
  }

  .close-btn {
    font-size: 20px;
  }

  .modal-actions {
    display: block;
    margin-top: 15px;
  }

  .confirm-btn,
  .cancel-btn {
    width: 100%;
    padding: 12px;
    font-size: 14px;
    margin-bottom: 10px;
  }

  .confirm-btn {
    background-color: #007bff;
    color: white;
  }

  .cancel-btn {
    background-color: #ddd;
    color: black;
  }

 
  .courtlist-dropdown-menu {
    width: auto;
    padding: 0;
  }

  .courtlist-checkbox {
    font-size: 12px;
  }

}.court-timings-container {
  padding: 10px; 
  margin: 10px auto;
  max-width: 1200px; 
  background-color: #f9f9f9; 
  border-radius: 8px;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.1); 
}
