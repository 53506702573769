/* CreateBenefits.css */

.create-benefits-container {
    display: flex;
    flex-direction: column;

    justify-content: center;
    padding: 20px;
    background-color: #f4f4f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 1000px;
    margin: 20px auto;
  }

  .input-container {
    width: 100%;
    margin-bottom: 20px;
  }
  
  .benefit-input {
    width: 100%;
    padding: 12px;
    font-size: 16px;
    border: 2px solid #ddd;
    border-radius: 8px;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
  }
  
  .benefit-input:focus {
    border-color: #4CAF50;
    outline: none;
  }
  
  .submit-container {
    width: 100%;
    display: flex;
  
  }
  
  .submit-btn {
    background-color: #4CAF50;
    color: white;
    font-size: 18px;
    padding: 12px 25px;
    border: none;
    border-radius: 25px;
    cursor: pointer;
    transition: background-color 0.3s ease, transform 0.3s ease;
  }
  
  .submit-btn:hover {
    background-color: #45a049;
    transform: translateY(-3px);
  }
  
  .submit-btn:active {
    background-color: #389e3d;
    transform: translateY(2px);
  }
  
  .submitted-benefit {
    margin-top: 20px;
    background-color: #e7f5e7;
    padding: 15px;
    border-radius: 8px;
    border: 1px solid #c8e6c9;
    width: 100%;
    max-width: 450px;
    text-align: center;
  }
  
  .submitted-benefit h3 {
    color: #388e3c;
    margin-bottom: 10px;
  }
  
  .submitted-benefit p {
    font-size: 16px;
    color: #555;
  }
  