.update-bank-container {
  max-width: 1000px;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.update-bank-container h2 {
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.form-group {
  margin-bottom: 15px;
}

.form-group label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
  color: #555;
}

.form-input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  color: #333;
  background-color: #fff;
  transition: border-color 0.3s ease;
}

.form-input:disabled {
  background-color: #e9ecef;
  cursor: not-allowed;
}

.form-input:focus {
  border-color: #007bff;
  outline: none;
}

.bank-transfer-toggle-button {
  display: block;
  width: 100%;
  padding: 10px;
  background-color: #006bb3;
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.bank-transfer-button {
  padding: 10px 16px;
  font-size: 16px;
  font-weight: bold;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  color: white;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.bank-transfer-button.disable {
  background-color: #d9534f;
}

.bank-transfer-button.enable {
  background-color: #006bb3;
}


.status-text {
  text-align: center;
  margin-left: 1%;
}