  .booking-container {
    padding: 20px;
    margin: 20px auto;
    max-width: 1200px;
    background: #f4f6f9;
    border-radius: 10px;
    box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
  }

  .booking-title {
    text-align: center;
    font-size: 2.2rem;
    color: #333;
    margin-bottom: 20px;
  }

  .booking-controls {
    display: flex;
    justify-content: space-between;
    gap: 20px;
    margin-bottom: 20px;
  }

  .booking-control {
    flex: 1;
  }

  .booking-control label {
    font-size: 1rem;
    margin-bottom: 5px;
    display: block;
    color: #555;
  }

  .booking-control select,
  .booking-control input {
    width: 100%;
    padding: 10px;
    font-size: 1rem;
    border: 1px solid #ddd;
    border-radius: 5px;
    transition: 0.3s;
  }

  .booking-control select:focus,
  .booking-control input:focus {
    outline: none;
    border-color: #007bff;
  }

  .booking-main-button {
    display: block;

    padding: 12px 12px;
    margin-bottom: 20px;
    background-color: #003366;
    color: white;
    font-size: 1.1rem;
    border: none;
    border-radius: 5px;
    cursor: pointer;
    transition: 0.3s;
  }

  .table-container {
    overflow-x: auto;
  }

  .booking-table {
    width: 100%;
    border-collapse: collapse;
  }

  .booking-table th,
  .booking-table td {
    border: 1px solid #ddd;
    padding: 10px;
    text-align: center;
    font-size: 0.9rem;
  }

  .booking-table th {
    background-color: #003366;
    color: white;
    font-weight: bold;
  }

  .booking-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }

  .booking-table tr:hover {
    background-color: #f1f1f1;
  }

  .booking-button {
    padding: 8px 12px;
    font-size: 0.9rem;
    color: white;
    background-color: #003366;
    border: none;
    border-radius: 5px;
    cursor: pointer;
  }

  .booking-button:hover {
    background-color: #0056b3;
  }

  .booking-button.selected {
    background-color: #28a745;
    font-weight: bold;
    color: white;
  }

  canvas {
    max-height: 400px;
  }

  .chart-container-court {

    padding: 20px;
    background: white;
    border-radius: 8px;
    box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    border: 1px solid #ddd;
  }

  .chart-container-court .chartjs-tooltip {
    background: rgba(0, 0, 0, 0.9) !important;
    border-radius: 4px !important;
    color: white !important;
    padding: 10px !important;
    white-space: pre-wrap !important;
    max-width: 300px !important;
  }

  .chart-container-court .chartjs-tooltip-key {
    display: inline-block;
    width: 10px;
    height: 10px;
    margin-right: 5px;
  }

  .bc-button {
    padding: 8px 12px;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    font-size: 14px;
    transition: background-color 0.3s ease;
  }

  .bc-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
  }

  .bc-button:not(:disabled) {
    background-color: #28a745;
    color: white;
  }

  .bc-button:not(:disabled):hover {
    background-color: #218838;
  }

  .bc-selected {
    background-color: #dc3545 !important;
  }

  .bc-selected:hover {
    background-color: #c82333 !important;
  }

  .booking-main-button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
    opacity: 0.6;
  }
  .booking-container-confirmation {
  
    background: #fff;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  }
  
  .booking-title {
    text-align: center;
    color: #2c3e50;
    margin-bottom: 20px;
  }
  
  .booking-section-court, .booking-section {
    margin-bottom: 20px;
  }
  
  .section-title {
    color: #2c3e50;
    margin-bottom: 10px;
  }

  .court-table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;
  }
  
  .court-table th,
  .court-table td {
    border: 1px solid #ddd;
    padding: 8px;
    text-align: left;
  }
  
  .court-table th {
    background-color: #f3f3f3;
    font-weight: bold;
  }
  
  .court-table tr:nth-child(even) {
    background-color: #f9f9f9;
  }
  
  .court-table tr:hover {
    background-color: #f1f1f1;
  }
  
  .user-input-container {
    margin-bottom: 20px;
  }
  
  .input-group-court {
    margin-bottom: 15px;
  }
  
  .user-label {
    display: block;
    margin-bottom: 5px;
    font-size: 14px;
    color: #34495e;
  }
  
  .user-input-court {
    width: 99%;
    padding: 10px;
    font-size: 14px;
    border: 1px solid #bdc3c7;
    border-radius: 4px;
    background: #ecf0f1;
    color: #34495e;
  }
  
  .user-input-court:focus {
    outline: none;
    border-color: #3498db;
  }
  
  .collection-option {
    display: block;
    margin-bottom: 10px;
    font-size: 14px;
    color: #34495e;
  }
  
  .radio-input {
    margin-right: 10px;
  }
  
  .confirm-booking-btn {
    display: block;
    width: 100%;
    padding: 10px;
    background: #2ecc71;
    color: #fff;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    cursor: pointer;
    text-align: center;
  }
  
  .confirm-booking-btn:hover {
    background: #27ae60;
  }
  
  .amount, .total-amount {
    font-size: 16px;
    color: #34495e;
  }
  
  .amount span, .total-amount span {
    font-weight: bold;
  }
  /* Modal Overlay */
.booking-calendar-modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  z-index: 1000;
}

/* Modal Content */
.booking-calendar-modal-content {
  background-color: white;
  width: 30%;
  height: 100%;
  padding: 20px;
  box-shadow: -2px 0 5px rgba(0, 0, 0, 0.2);
  overflow-y: auto;
  position: relative;
}

/* Close Button */
.booking-calendar-close-modal {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 20px;
  cursor: pointer;
  color: #000;
}

.booking-calendar-close-modal:hover {
  color: #ff0000; /* Change color on hover for better UX */
}